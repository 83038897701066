import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {IconButton} from "@mui/material";
import RemediationDetailsDomandeModal from "../Ui/RemediationDetailsDomandeModal";
import {cleanPercentage, getIcon, weightFromPercentage} from "../Service/Shared";
import SvgPieProgress from "../Custom/MySVG/SvgPieProgress";

function filterRowsByStandard(standard, rows) {
	if (standard.length > 0) {
		if (standard === "Tutti gli standard") {
			return rows;
		} else {
			rows = rows.filter((item) => {
				return item.standard === standard;
			});
		}
	}
	return rows;
}

function filterRowsByName(query, rows) {
	const arrayFiltered = rows.filter(function (el) {
		return el.name.toLowerCase().includes(query.toLowerCase());
	});
	return arrayFiltered;
}

function filterRowsByStrategia(rows, strategia) {
	function compareByCopertura(a, b) {
		if (a.chartsDataCounts.coperta > b.chartsDataCounts.coperta) {
			return 1;
		} else if (a.chartsDataCounts.coperta < b.chartsDataCounts.coperta) {
			return -1;
		} else if (a.chartsDataCounts.piuCoperta > b.chartsDataCounts.piuCoperta)
			return 1;
		else if (a.chartsDataCounts.piuCoperta < b.chartsDataCounts.piuCoperta)
			return -1;
		else if (
			a.chartsDataCounts.parzialmenteCoperta >
			b.chartsDataCounts.parzialmenteCoperta
		)
			return 1;
		else if (
			a.chartsDataCounts.parzialmenteCoperta <
			b.chartsDataCounts.parzialmenteCoperta
		)
			return -1;
		else if (a.chartsDataCounts.piuScoperta > b.chartsDataCounts.piuScoperta)
			return 1;
		else if (a.chartsDataCounts.piuScoperta < b.chartsDataCounts.piuScoperta)
			return -1;
		else if (a.chartsDataCounts.scoperta > b.chartsDataCounts.scoperta)
			return 1;
		else if (a.chartsDataCounts.scoperta < b.chartsDataCounts.scoperta)
			return -1;
		return 0;
	}

	if (strategia === "Nessuna strategia") return rows;
	if (strategia === "Minimum Coverage Strategy")
		return rows.sort(compareByCopertura);
	if (strategia === "Greedy Coverage Strategy")
		return rows.sort(compareByCopertura).reverse();
}

function filterRowsByCosto(rows, costo) {
	let arrayResult = [];

	if (costo === "Tutti") return rows;
	rows.forEach((controllo) => {
		controllo.domande.forEach((domanda) => {
			if (costo === "Non specificato") {
				if (domanda.remediation.costo === "") {
					arrayResult.push(controllo);
				}
			} else {
				if (domanda.remediation.costo === costo) arrayResult.push(controllo);
			}
		});
	});

	arrayResult = arrayResult.filter((value, index) => {
		const _value = JSON.stringify(value);
		return (
			index ===
			arrayResult.findIndex((obj) => {
				return JSON.stringify(obj) === _value;
			})
		);
	});
	return arrayResult;
}

function filterRowsByDifficolta(rows, difficolta) {
	let arrayResult = [];

	if (difficolta === "Tutte") return rows;
	rows.forEach((controllo) => {
		controllo.domande.forEach((domanda) => {
			if (difficolta === "Non specificato") {
				if (domanda.remediation.difficolta === "") {
					arrayResult.push(controllo);
				}
			} else {
				if (domanda.remediation.difficolta === difficolta)
					arrayResult.push(controllo);
			}
		});
	});

	arrayResult = arrayResult.filter((value, index) => {
		const _value = JSON.stringify(value);
		return (
			index ===
			arrayResult.findIndex((obj) => {
				return JSON.stringify(obj) === _value;
			})
		);
	});
	return arrayResult;
}

function getCounterControlli(controlli) {
	let countNonCoperta = 0;
	let countParzialmenteCoperta = 0;
	let countCoperta = 0;
	let countPiuCoperta = 0;
	let countPiuScoperta = 0;

	controlli.domande.forEach((domanda) => {
		switch (domanda.copertura) {
			case "Non Coperta":
				countNonCoperta++;
				break;
			case "Più coperta che scoperta":
				countPiuCoperta++;
				break;
			case "Più scoperta che coperta":
				countPiuScoperta++;
				break;
			case "Coperta":
				countCoperta++;
				break;
			case "Parzialmente Coperta":
				countParzialmenteCoperta++;
				break;
			default:
				break;
		}
	});

	return {
		countNonCoperta,
		countParzialmenteCoperta,
		countCoperta,
		countPiuCoperta,
		countPiuScoperta,
	};
}

function getTablesData(
	inputs,
	storicoAssets,
	searchQuery,
	strategia,
	standard,
	difficolta,
	costo
) {
	let arrayResult = [];
	let dateStorico = "";
	if (storicoAssets && storicoAssets[0]) {
		dateStorico = storicoAssets[0].toString();
	}

	if (dateStorico) {
		const selectedInput = inputs.filter((input) => {
			const dateInput = input.dataUltimaModifica.toString();
			return dateInput === dateStorico;
		});
		selectedInput[0].controlli.forEach((controllo) => {
			const {
				countNonCoperta,
				countParzialmenteCoperta,
				countCoperta,
				countPiuCoperta,
				countPiuScoperta,
			} = getCounterControlli(controllo);

			let objResult = {
				id: controllo.idControllo,
				name: controllo.controlloRif,
				percentage: controllo.copertura,
				standard: controllo.titoloStandard,
				domande: controllo.domande,
				chartsDataCounts: {
					nonCoperta: countNonCoperta,
					parzialmenteCoperta: countParzialmenteCoperta,
					coperta: countCoperta,
					piuCoperta: countPiuCoperta,
					piuScoperta: countPiuScoperta,
				},
			};
			if (objResult.percentage !== "100.0%") {
				arrayResult.push(objResult);
			}
		});
	} else {
		inputs[0].controlli.forEach((controllo) => {
			const {
				countNonCoperta,
				countParzialmenteCoperta,
				countCoperta,
				countPiuCoperta,
				countPiuScoperta,
			} = getCounterControlli(controllo);

			let objResult = {
				id: controllo.idControllo,
				name: controllo.controlloRif,
				percentage: controllo.copertura,
				standard: controllo.titoloStandard,
				domande: controllo.domande,
				chartsDataCounts: {
					nonCoperta: countNonCoperta,
					parzialmenteCoperta: countParzialmenteCoperta,
					coperta: countCoperta,
					piuCoperta: countPiuCoperta,
					piuScoperta: countPiuScoperta,
				},
			};
			if (objResult.percentage !== "100.0%") {
				arrayResult.push(objResult);
			}
		});
	}

	if (!searchQuery && strategia) {
		const orderedByName = filterRowsByName(searchQuery, arrayResult);
		const orderedByCopertura = filterRowsByStrategia(orderedByName, strategia);
		const filteredByStandard = filterRowsByStandard(
			standard,
			orderedByCopertura
		);
		arrayResult = filteredByStandard;
	} else if (searchQuery && !strategia) {
		const orderedByName = filterRowsByName(searchQuery, arrayResult);
		const filteredByStandard = filterRowsByStandard(standard, orderedByName);
		arrayResult = filteredByStandard;
	} else if (searchQuery && strategia) {
		const orderedByName = filterRowsByName(searchQuery, arrayResult);
		const orderedByCopertura = filterRowsByStrategia(orderedByName, strategia);
		const filteredByStandard = filterRowsByStandard(
			standard,
			orderedByCopertura
		);
		arrayResult = filteredByStandard;
	}
	if (costo && difficolta) {
		const filteredByCosto = filterRowsByCosto(arrayResult, costo);
		const filteredByDifficolta = filterRowsByDifficolta(
			filteredByCosto,
			difficolta
		);
		arrayResult = filteredByDifficolta;
	}

	return arrayResult;
}

function EnhancedTableHead(props) {
	const headCells = props.headCells;
	const {order, orderBy, onRequestSort} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow
				sx={{
					"& th": {
						color: "#033661",
						fontWeight: 600,
						"& .MuiTableSortLabel-root.Mui-active": {
							color: "#003f9d!important",
						},
						"&.MuiTableCell-root": {padding: "8px !important"},
					},
				}}
			>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.field}
						align={"center"}
						sx={{whiteSpace: "nowrap"}}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.field ? order : false}
					>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function RemediationFullTable(props) {
	const inputs = props.inputs;
	const [openDetailRemediation, setOpenDetailRemediation] =
		React.useState(false);
	const [row, setRow] = React.useState({});

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("costo");
	const [page, setPage] = React.useState(0);
	const rowsPerPageOptions = [5, 10, 25, 50, 100, 200, 500];
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const rows = getTablesData(
		inputs,
		props.selectedStorico,
		props.searchQuery,
		props.strategia,
		props.standard,
		props.difficolta,
		props.costo
	);

	const handleOpenRemediationDetail = (row) => {
		setOpenDetailRemediation(true);
		setRow(row);
	};

	const handleCloseRemediationDetail = () => {
		setOpenDetailRemediation(false);
		setRow(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = 0;
	const remeIconActive =  getIcon("Remediation",{style:{fill: "#033661"}});
	const remeIconInactive =  getIcon("Remediation",{style:{margin: ".75rem", color: "#ccc", pointerEvents: "none"}});
	return (
		<Box sx={{width: "100%"}}>
			<TableContainer>
				<Table
					sx={{minWidth: 750, width: "99%"}}
					aria-labelledby="tableTitle"
					size={"medium"}
				>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows?.length}
						headCells={props.headCells}
					/>
					<TableBody
						sx={{"& td": {color: "#033661", padding: "4px 8px 0 8px"}}}
					>
						{rows.length > 0 &&
							rows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								?.map((row, index) => {
									return (
										<TableRow key={row.name}>
											<TableCell sx={{width: "5%",whiteSpace:"pre"}}>
												{row.name.replace(/ .*/, '')}
											</TableCell>
											<TableCell sx={{width: "75%",fontWeight:"bold"}}>
												{row.name.replace(/^[^ ]+ /, '')}
											</TableCell>
											<TableCell sx={{textAlign: "center",width:"10%"}}>
												<SvgPieProgress
													value={parseFloat(row.percentage)}
													size={56}
													label={cleanPercentage(row.percentage)}
													className={ weightFromPercentage(row.percentage) }
													border={1}
													borderOffset={0}
													hole={.75}
												/>
											</TableCell>
											<TableCell sx={{width: "10%"}} align={"center"}>
												{row.copertura !== "Coperta" ? (
													<Tooltip title="Dettaglio Remediation">
														<IconButton
															onClick={() => {
																handleOpenRemediationDetail(row);
															}}
														>
															{ remeIconActive }
														</IconButton>
													</Tooltip>
												) : (
													<Tooltip title="Dettaglio Remediation non disponibile">
														<IconButton
														>
															{ remeIconInactive }
														</IconButton>
													</Tooltip>
												)}
											</TableCell>
										</TableRow>
									);
								})}
						{emptyRows > 0 && (
							<TableRow style={{height: 53 * emptyRows}}>
								<TableCell colSpan={6}/>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={rowsPerPageOptions}
				component="div"
				count={rows?.length}
				onRowsPerPageChange={handleChangeRowsPerPage}
				page={page}
				rowsPerPage={rowsPerPage}
				onPageChange={handleChangePage}
				labelRowsPerPage={"Righe per pagina"}
				labelDisplayedRows={({from, to, count}) => {
					return `${from}–${to} di ${count !== -1 ? count : `più di ${to}`}`;
				}}
				sx={{"& .MuiTablePagination-toolbar": {alignItems: "baseline"}}}
			/>
			<RemediationDetailsDomandeModal
				open={openDetailRemediation}
				content={row}
				difficolta={props.difficolta}
				costo={props.costo}
				handleClose={handleCloseRemediationDetail}
				title="Dettaglio remediation"
			/>
		</Box>
	);
}

export default RemediationFullTable;
