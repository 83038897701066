import './assets/css/App.css';
import Header from './Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <SnackbarProvider maxSnack={4}>
      <Header></Header>
    </SnackbarProvider>
  );
}

export default App;
