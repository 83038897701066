/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import "./assets/css/Header.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import {
	Paper,
	Collapse,
	Tooltip,
	Avatar,
	Menu,
	MenuItem,
	Button,
	FormControl,
	InputLabel,
	Select,
} from "@mui/material/";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import {Link, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {
	ChevronLeft,
	ConstructionOutlined,
	KeyboardArrowDown,
} from "@mui/icons-material";
import Gerarchie from "./Components/Gerarchie";
import Dashboard from "./Components/Dashboard";
import Confronto from "./Components/Confronto";
import Remediation from "./Components/Remediation";
import {useLocation} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Login from "./Components/Login";
import Upload from "./Components/Upload";
import {connect} from "react-redux";
import {
	getByFilter,
	setError,
	setInfo,
	setLoading,
	setSuccess,
	setWarning,
	setSelectStorico,
} from "./Reducer/AppReducer";
import {setLogout} from "./Reducer/AuthReducer/authAction";
import Registrazione from "./Components/Registrazione";
import {useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import Report from "./Components/Report";
import {cleanPercentage} from "./Service/Shared";
import SvgPieProgress from "./Custom/MySVG/SvgPieProgress";

const drawerWidth = 350;

const map = [];
map["Basso"] = 1;
map["Medio"] = 2;
map["Elevato"] = 3;

const getMenuList = (dataPlatform, selectStorico) => {
	var gerarchie = [];
	var controlli = [];
	var mainMenu = [];
	var dataPlatformInput = [];
	let percentages = [];

	if (selectStorico && selectStorico.length === 0) {
		dataPlatformInput =
			dataPlatform &&
			dataPlatform.platform &&
			dataPlatform.platform.inputs[dataPlatform.platform.inputs.length - 1];
	} else {
		dataPlatformInput =
			dataPlatform &&
			dataPlatform.platform &&
			dataPlatform.platform.inputs.filter(
				(a) => a.dataCaricamento === selectStorico[0]
			);
		if (Array.isArray(dataPlatformInput) && dataPlatformInput.length >= 1)
			dataPlatformInput = dataPlatformInput[0];
	}
	if (
		!dataPlatformInput ||
		Array.isArray(dataPlatformInput) ||
		typeof dataPlatformInput !== "object"
	)
		dataPlatformInput = {};
	if (dataPlatformInput && !Array.isArray(dataPlatformInput.controlli))
		dataPlatformInput.controlli = [];
	dataPlatformInput.controlli.forEach((controllo, indexControllo) => {
		const currentIndex = mainMenu.indexOf(controllo.titoloStandard);
		if (currentIndex === -1) {
			mainMenu.push(controllo.titoloStandard);
		}
		controllo.gerarchie.forEach((gerarchia, indexGerarchia) => {
			const currentIndexControllo = controlli.indexOf(gerarchia);
			if (indexGerarchia === 0) {
				if (currentIndexControllo === -1) {
					let percentageObj = {
						key: gerarchia,
						value: controllo.copertura,
						std: controllo.titoloStandard
					};

					percentages.push(percentageObj);

					controlli.push(gerarchia);
				}
				gerarchie.push(controllo.gerarchie.filter((g) => g !== gerarchia));
				let uniqueGer = Array.from(
					new Set(gerarchie.map(JSON.stringify)),
					JSON.parse
				);
				gerarchie = uniqueGer;
			}
		});
	});

	let data = [];
	controlli.forEach((c, index) => {
		data[index] = [];

		let split = c.split(" ");
		gerarchie.forEach((g) => {
			if ( ! ( g && g[0] ) ) return;
			if (g[0].includes(split[0])) {
				const currentIndex = data[index].indexOf(g[0]);
				if (currentIndex === -1) {
					data[index].push(g[0]);
				}
			}
		});
	});
	gerarchie = data;

	percentages = getRealPercentages(percentages, dataPlatformInput.controlli);

	return {gerarchie, controlli, mainMenu, dataPlatformInput, percentages};
};

function getNumericValue(percentageString) {
}

function getRealPercentages(percentages, controlliDataPlatform) {
	let percentagesResult = [];
	percentages.forEach((percentage) => {
		const controlloRif = percentage.key;
		const children = controlliDataPlatform.filter(function (controllo) {
			return controllo.gerarchie[0] === controlloRif;
		});
		const totale = children.length;
		let mediaCopertura = 0;
		children.forEach((child) => {
			mediaCopertura += Number(child.copertura.replace("%", ""));
		});
		const mediaFinale = (mediaCopertura / totale).toFixed(2).toString() + "%";
		const percentageObj = {
			key: controlloRif,
			value: mediaFinale,
			std: percentage.std
		};
		percentagesResult.push(percentageObj);
	});
	return percentagesResult;
}

function ClippedDrawer(props) {
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [open, setOpen] = React.useState(true);
	const [select, setSelected] = React.useState([]);
	const [openList, setOpenList] = React.useState([]);
	const [openMainList, setOpenMainList] = React.useState([]);
	const navigate = useNavigate();
	const [offHeightApp, setOffHeightApp] = React.useState(0);
	const {gerarchie, controlli, mainMenu, dataPlatformInput, percentages} =
		getMenuList(props.dataPlatform, props.selectStorico);
	const [topFiveRemediation, setTopFiveRemediation] = React.useState([]);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const settings =
		props.utente?.admin === true ? ["Censisci Utente", "Logout"] : ["Logout"];
	const location = useLocation();

	const action = (key) => (
		<>
			<Button
				style={{color: "white"}}
				onClick={() => {
					closeSnackbar(key);
				}}
			>
				<CloseIcon/>
			</Button>
		</>
	);
	React.useEffect(() => {
		if (window.location.pathname === "/dashboard") setOpen(true);
		if (window.location.pathname === "/login") setOpen(false);
	}, [location]);

	const handleClose = () => {
		if (props.error === true) props.setError(false);
		if (props.success === true) props.setSuccess(false);
		if (props.warning === true) props.setWarning(false);
		if (props.info === true) props.setInfo(false);
	};

	React.useEffect(() => {
		if (
			(props.error === true ||
				props.success === true ||
				props.warning === true ||
				props.info === true) &&
			props.message != null &&
			props.message?.trim()?.length > 0
		) {
			enqueueSnackbar(props.message, {
				action,
				autoHideDuration: 3000,
				disableWindowBlurListener: true,
				variant: props.success
					? "success"
					: props.error
						? "error"
						: props.warning
							? "warning"
							: "info",
				onEntered: handleClose(),
			});
		}
	}, [props.error, props.success, props.warning, props.info]);

	React.useEffect(() => {
		let offsetHeight = document.getElementById("appBar").offsetHeight;
		setOffHeightApp(offsetHeight);
	}, []); //didmount

	React.useEffect(() => {
		if (props.utente?.username && props.token) {
			props.getByFilter(props.utente.username);
		}
	}, [props.utente]);

	React.useEffect(() => {
		getTopFiveRem();
	}, [props.dataPlatform, props.selectStorico]);

	const getTopFiveRem = () => {
		let topFive = getTopFive(dataPlatformInput.controlli);
		topFive = topFive.flat();
		if (topFive?.length > 5) {
			topFive = topFive
				?.sort((a, b) => {
					if ((a != null && b == null) || map[a.costo] > map[b.costo]) return 1;
					else if ((a == null && b != null) || map[a.costo] < map[b.costo])
						return -1;
					else return 0;
				})
				?.slice(0, 5);
		}
		if (topFive && topFive?.length > 0) setTopFiveRemediation([...topFive]);
	};

	const getTopFive = (dataInput) => {
		dataInput = dataInput
			?.map((controllo) => controllo.domande.map((dom) => dom?.remediation))
			?.slice();
		dataInput = dataInput?.filter((r) => r[0] !== "" && r[0] && r[0] != null);
		return dataInput;
	};

	React.useEffect(() => {
		if (window.location.pathname === "/login") setOpen(false);
		if (window.location.pathname === "/dashboard") setOpen(true);
	}, []);

	React.useEffect(() => {
		if (window.location.pathname === "/login") setOpen(false);
		if (!open && window.location.pathname === "/dashboard") {
			setOpenList([]);
			setOpenMainList([]);
		}
	}, [open]);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleClickMenu = (setting) => {
		if (setting === "Logout") {
			setOpen(false);
			props.setLogout();
		} else if (setting === "Censisci Utente") {
			navigate("/registrazione");
		}
		setAnchorElUser(null);
	};

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	const selected = (text) => {
		let path = "";
		if (window.location.pathname === "/") {
			path = "dashboard";
			return path === text ? true : false;
		} else {
			path = window.location.pathname.split("/");
			return path[1] === text ? true : false;
		}
	};

	const selectedStorico = (value) => {
		const currentIndex = props.selectStorico.indexOf(value);
		const newSelectList = [];

		if (currentIndex === -1) {
			newSelectList.push(value);
			props.setSelectStorico(newSelectList);
		}
	};

	React.useEffect(() => {
		if (props.dataPlatform) {
			let index = props.dataPlatform.platform?.inputs?.length - 1;
			selectedStorico(
				props.dataPlatform.platform?.inputs[index]?.dataCaricamento
			);
			if (
				props.dataPlatform.platform?.inputs?.length === 0 ||
				!props.dataPlatform.platform?.hasOwnProperty("inputs")
			) {
				navigate("/uploadFile");
			}
		}
	}, [props.dataPlatform]);

	const handleOpenList = (value) => {
		const currentIndex = openList.indexOf(value);
		const newOpenList = [];

		if (currentIndex === -1) {
			newOpenList.push(value);
		} else {
			newOpenList.splice(currentIndex, 1);
		}

		setOpenList(newOpenList);
	};

	const handleOpenMainList = (value) => {
		const currentIndex = openMainList.indexOf(value);
		const newOpenList = [];

		if (currentIndex === -1) {
			newOpenList.push(value);
		} else {
			newOpenList.splice(currentIndex, 1);
		}
		setOpenList([]);
		setOpenMainList(newOpenList);
	};

	const toUppercase = (text) => {
		return text !== undefined && text != null ? text?.toUpperCase() : "";
	};

	React.useEffect(() => {
		selected(select);
		return () => {
		};
	}, [window.location.pathname, select]); // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<Box sx={{display: "flex"}} className={"SiteHeader"}>
			<CssBaseline/>
			<AppBar
				id="appBar"
				sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
			>
				<div className={"colorProgressBar"} />
				<Toolbar>
					{props.token && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{mr: 2}}
						>
							{!open ? (
								<MenuIcon color="disabled"/>
							) : (
								<ChevronLeft color="disabled"/>
							)}
						</IconButton>
					)}
					<Link style={{textDecoration: "none"}} to={"/dashboard"}>
						<Typography variant="h6" noWrap component="div" className={"siteLogo"}/>
					</Link>
				</Toolbar>
				<Toolbar>
					{props.token && (
						<Box className={"rightPortion"}>
							{props.dataPlatform != null && (
								<FormControl
									fullWidth
									sx={{display: "none", m: 1, minWidth: "300px"}}
								>
									<InputLabel
										id="demo-simple-select-autowidth-label"
										style={{color: "white"}}
									>
										STORICO ASSESSMENT
									</InputLabel>
									<Select
										labelId="demo-simple-select-autowidth-label"
										id="demo-simple-select-autowidth"
										value={props.selectStorico}
										onChange={(e) => selectedStorico(e.target.value)}
										label="STORICO ASSESSMENT"
										defaultValue={props.selectStorico}
										sx={{
											"& .MuiTypography-root": {
												fontWeight: 600,
												color: "white",
											},
										}}
									>
										{props.dataPlatform != null &&
											props.dataPlatform?.platform?.inputs?.length > 0 &&
											props.dataPlatform?.platform?.inputs?.map(
												(input, index) => {
													return (
														<MenuItem
															key={index}
															value={input.dataCaricamento}
															selected={
																props.selectStorico.length > 0
																	? props.selectStorico.indexOf(
																	input.dataCaricamento
																) !== -1
																	: index ===
																	props.dataPlatform.platform.inputs.length -
																	1
															}
														>
															<ListItemText
																style={{fontWeight: 600, color: "#033661"}}
																primary={input.dataCaricamento}
															/>
														</MenuItem>
													);
												}
											)}
									</Select>
								</FormControl>
							)}
							<Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
									<Avatar
										alt={toUppercase(props?.utente?.username)}
										src="/static/images/avatar/2.jpg"
									/>
								</IconButton>
							</Tooltip>
							<Menu
								sx={{mt: "45px"}}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{vertical: "top", horizontal: "right"}}
								keepMounted
								transformOrigin={{vertical: "top", horizontal: "right"}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								{settings.map((setting, index) => (
									<MenuItem
										key={setting}
										onClick={() => handleClickMenu(setting)}
									>
										<Typography textAlign="center">{setting}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
					)}
				</Toolbar>
			</AppBar>
			<Drawer className={"leftDrawer"}
					variant="persistent"
					anchor="left"
					open={open}
			>
				<Toolbar/>
				<Box sx={{overflow: "auto"}}>
					<PerfectScrollbar
						suppressContentEditableWarning
						suppressHydrationWarning
					>
						<List className="custom-scrollbar">
							<Link style={{textDecoration: "none"}} to={"/dashboard"}>
								<ListItem
									onClick={() => setSelected("dashboard")}
									selected={selected("dashboard")}
								>
									<ListItemText primary={"DASHBOARD"}/>
								</ListItem>
							</Link>
							<Divider/>
							{Array.isArray(mainMenu) &&
								mainMenu?.length > 0 &&
								mainMenu.map((itemMenu) => {
									return (
										<React.Fragment key={itemMenu}>
											<ListItem
												onClick={() => {
													handleOpenMainList(itemMenu);
												}}
												button
											>
												<ListItemText primary={itemMenu}/>
												<KeyboardArrowDown
													className={"chevronIcon " + (openMainList.indexOf(itemMenu) !== -1 ? "opened" : "closed")}/>
											</ListItem>
											<Divider/>
											<Collapse
												in={openMainList.indexOf(itemMenu) !== -1}
												timeout="auto"
												unmountOnExit
											>
												<List
													component="div"
													disablePadding
												>
													{percentages.map((percentage, indexControllo) => {
														if ( percentage.std !== itemMenu ) return "";
														return (
															<React.Fragment key={itemMenu + indexControllo}>
																<ListItem
																	onClick={() => {
																		handleOpenList(percentage.key);
																	}}
																	button
																>
																	<ListItemText primary={percentage.key}/>
																	<SvgPieProgress
																		size={20}
																		value={parseFloat(percentage.value||0)}
																		title={cleanPercentage(percentage.value)}
																	/>
																	<KeyboardArrowDown
																		className={"chevronIcon " + (openList.indexOf(percentage.key) !== -1 ? "opened" : "closed")}/>
																</ListItem>
																<Divider/>
																<Collapse
																	in={openList.indexOf(percentage.key) !== -1}
																	timeout="auto"
																	unmountOnExit
																>
																	<List
																		component="div"
																		disablePadding
																	>
																		{gerarchie[indexControllo].map(
																			(gerarchia, indexGerarchia) => {
																				return (
																					<Link
																						key={indexGerarchia + gerarchia}
																						style={{textDecoration: "none"}}
																						to={ "/controllo-" + gerarchia.replace(/ .+$/,'') }
																					>
																						<ListItem
																							onClick={() =>
																								setSelected(gerarchia)
																							}
																							selected={selected(
																								"controllo-"+gerarchia.replace(/ .+$/,'')
																							)}
																							button
																						>
																							<ListItemText primary={gerarchia}/>
																						</ListItem>
																					</Link>
																				);
																			}
																		)}
																	</List>
																	<Divider/>
																</Collapse>
															</React.Fragment>
														);
													})}
												</List>
											</Collapse>
										</React.Fragment>
									);
								})}

							<Link style={{textDecoration: "none"}} to={"/uploadFile"}>
								<ListItem
									onClick={() => setSelected("uploadFile")}
									selected={selected("uploadFile")}
									button
								>
									<ListItemText primary={"UPLOAD ASSESSMENT"}/>
								</ListItem>
							</Link>

							<Divider/>
							<Link style={{textDecoration: "none"}} to={"/report"}>
								<ListItem
									onClick={() => setSelected("report")}
									selected={selected("report")}
									button
								>
									<ListItemText primary={"REPORT"}/>
								</ListItem>
							</Link>
							<Divider/>
							<Link style={{textDecoration: "none"}} to={"/remediation"}>
								<ListItem
									onClick={() => setSelected("remediation")}
									selected={selected("remediation")}
									button
								>
									<ListItemText primary={"REMEDIATION"}/>
								</ListItem>
							</Link>
							<Divider/>
							<Link style={{textDecoration: "none"}} to={"/confronto"}>
								<ListItem
									onClick={() => setSelected("confronto")}
									selected={selected("confronto")}
									button
								>
									<ListItemText primary={"CONFRONTO ASSESSMENT"}/>
								</ListItem>
							</Link>
							<Divider/>
						</List>
					</PerfectScrollbar>
				</Box>
			</Drawer>
			<Box
				component="main"
				sx={{
					transition: open
						? "margin 230ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
						: "margin 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
					marginLeft: !open ? "-" + drawerWidth + "px" : 0,
				}}
			>
				<Paper className={"mainContent"}
					   elevation={3}
				>
					{props.token ? (
						<Routes>
							<Route path="*" element={<Navigate to={"/dashboard"}/>}/>
							<Route path="/" element={<Navigate to={"/dashboard"}/>}/>
							{/*
              <Route
                path="/dashboard"
                element={
                  <Home
                    selectedStorico={props.selectStorico}
                    topFiveRemediation={topFiveRemediation}
                    openMenu={setOpen}
                    openListMenu={setOpenMainList}
                    open={open}
                    navigate={navigate}
                    selected={setSelected}
                    loading={props.loading}
                    mainMenu={mainMenu}
                  />
                }
              />
              */}
							<Route
								path="/dashboard"
								element={
									<Dashboard
										selectedStorico={props.selectStorico}
										topFiveRemediation={topFiveRemediation}
										openMenu={setOpen}
										openListMenu={setOpenMainList}
										open={open}
										navigate={navigate}
										selected={setSelected}
										loading={props.loading}
									/>
								}
							/>
							{controlli.map((controllo, index) =>
								gerarchie[index].map((gerarchia, indexGerarchia) => (
									<Route
										key={indexGerarchia}
										path={"/controllo-" + gerarchia.replace(/ .+$/,'') }
										element={
											<Gerarchie
												dataPlatform={dataPlatformInput}
												controllo={controllo}
												gerarchia={gerarchia}
												selected={setSelected}
											/>
										}
									/>
								))
							)}
							<Route path="/uploadFile" element={<Upload/>}/>
							<Route
								path="/report"
								element={<Report selectedStorico={props.selectStorico}/>}
							/>
							<Route
								path="/remediation"
								element={<Remediation selectedStorico={props.selectStorico}/>}
							/>
							<Route
								path="/confronto"
								element={
									<Confronto
										selectedStorico={props.selectStorico}
										topFiveRemediation={topFiveRemediation}
										openMenu={setOpen}
										openListMenu={setOpenMainList}
										open={open}
										navigate={navigate}
										selected={setSelected}
										loading={props.loading}
									/>
								}
							/>
							<Route path="/registrazione" element={<Registrazione/>}/>
						</Routes>
					) : (
						<Routes>
							<Route path="*" element={<Navigate to={"/login"}/>}/>
							<Route path="/" element={<Navigate to={"/login"}/>}/>
							<Route path="/login" element={<Login/>}/>
						</Routes>
					)}
				</Paper>
			</Box>
		</Box>
	);
}

const mapStateToProps = (state) => {
	return {
		upload: state.appReducer.upload,
		dataPlatform: state.appReducer.dataPlatform,
		utente: state.authReducer.utente,
		token: state.authReducer.token,
		success: state.appReducer.success,
		error: state.appReducer.error,
		warning: state.appReducer.warning,
		info: state.appReducer.info,
		message: state.appReducer.message,
		loading: state.appReducer.loading,
		selectStorico: state.appReducer.selectStorico,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getByFilter: (data) => dispatch(getByFilter(data)),
		setLogout: () => dispatch(setLogout()),
		setError: (flag) => dispatch(setError(flag)),
		setSuccess: (flag) => dispatch(setSuccess(flag)),
		setWarning: (flag) => dispatch(setWarning(flag)),
		setInfo: (flag) => dispatch(setInfo(flag)),
		setLoading: (flag) => dispatch(setLoading(flag)),
		setSelectStorico: (flag) => dispatch(setSelectStorico(flag)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClippedDrawer);
