import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ProgressBar from "react-bootstrap/ProgressBar";
import {IconButton, Tooltip} from "@mui/material";
import ListRemediationsModal from "../../Ui/ListremediationsModal";
import {
	cleanPercentage, getIcon,
	weightFromPercentage
} from "../../Service/Shared";
import PieDomandeGruppoControlli from "../Common/PieDomandeGruppoControlli";
import SvgBulbProgress from "../../Custom/MySVG/SvgBulbProgress";
import SvgPieProgress from "../../Custom/MySVG/SvgPieProgress";

export default function TabellaControlli(props) {
	const [remediations, setRemediations] = React.useState({open: false});

	const handleOpenModal = (row) => {
		setRemediations({...remediations, open: true, list: row});
	};

	const handleCloseModal = () => {
		setRemediations({...remediations, open: false});
	};

	const getMaturita = (row) => {
		if (row.domande && row.domande.length) {
			if (row.domande.some(d => (d?.maturita === "Iniziale"))) return "Iniziale";
			if (row.domande.some(d => (d?.maturita === "Ripetibile"))) return "Ripetibile";
			if (row.domande.some(d => (d?.maturita === "Definito"))) return "Definito";
			if (row.domande.some(d => (d?.maturita === "Gestito"))) return "Gestito";
			if (row.domande.some(d => (d?.maturita === "Ottimizzazione"))) return "Ottimizzato";
			return "—";
		}
		return row.maturita ?? "—";
	};

	const remeIconActive =  getIcon("Remediation",{style:{fill: "#033661"}});
	const remeIconInactive =  getIcon("Remediation",{style:{margin: ".75rem", color: "#ccc", pointerEvents: "none"}});

	return (
		<React.Fragment>
			<TableContainer elevation={0} component={Paper}>
				<Table sx={{minWidth: 650, borderCollapse: "unset"}}>
					<TableHead>
						<TableRow sx={{"& th": {color: "#033661"}}}>
							<TableCell align="center">ID</TableCell>
							<TableCell align="center">TITOLO</TableCell>
							<TableCell align="center">COPERTURA</TableCell>
							<TableCell align="center">MATURITÀ</TableCell>
							<TableCell align="center">REMEDIATION</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.data.map((row) => (
							<TableRow
								key={row.idControllo}
								sx={{
									"&:last-child td, &:last-child th": {border: 0},
									"& td": {color: "#033661", padding: "4px 8px 0 8px"},
								}}
							>
								<TableCell align="center" sx={{ width: "2%", whiteSpace: "nowrap" }}>
									{row.controlloRif.replace(/ .*/, '')}
								</TableCell>
								<TableCell
									onClick={() => {
										props.handleClickTable(true, row.domande, row.controlloRif);
									}}
									sx={{
										fontWeight: 600,
										cursor: "pointer",
										color: "#003f9d!important",
										"&:hover": {
											textDecoration: "underline",
											color: "#033661 !important",
										},
									}}
									align="left"
								>
									{row.controlloRif.replace(/^[^ ]+ /, '')}
								</TableCell>
								<TableCell
									align="center"
									sx={{ width: "2%" }}
								>
									<SvgPieProgress
										value={parseFloat(row.copertura)}
										size={74}
										label={cleanPercentage(row.copertura)}
										border={1.5}
										borderOffset={1.5}
										hole={.75}
										className={ weightFromPercentage(row.copertura) }
									/>
								</TableCell>
								<TableCell align="center" sx={{width: "2%"}}>
									<SvgBulbProgress width={120} maturita={getMaturita(row)} />
								</TableCell>
								<TableCell align="center" sx={{width: "2%"}}>
									{ parseFloat(row.copertura) < 100 ? (
										<Tooltip title="Apri Lista Remediation">
											<IconButton onClick={() => { handleOpenModal(row.domande); }}>
												{ remeIconActive }
											</IconButton>
										</Tooltip>
									) : (
										<Tooltip title="Lista Remediation non disponibile">
											{ remeIconInactive }
										</Tooltip>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<div key={props.data[0]?.controlloRif}>
				<div className={"titoloSezione"} style={{marginTop:"2rem"}}>
					<p>Tutte le domande</p>
				</div>
				<PieDomandeGruppoControlli data={props} size={"l"} />
			</div>
			<ListRemediationsModal
				open={remediations.open}
				title="Lista Remediation"
				content={remediations.list}
				handleClose={handleCloseModal}
			/>
		</React.Fragment>
	);
}
