import React from "react";
import "./MySvg.css";
import {arcPathFromPercent, parseCircularProps} from "./MySvgShared";

const SvgPieProgress = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = parseCircularProps(props, {  value: parseFloat(props.value ?? 0) });
		if (props.label) {
			switch (typeof props.label) {
				case "number":
					this.state.label = String(props.label);
					break;
				case "boolean" :
					this.state.label = String(this.state.value) + "%";
					break;
				default:
					this.state.label = props.label;
			}
			this.state.labelStyle = {};
			if ( props.labelSize ) this.state.labelStyle.fontSize = typeof props.labelSize === "string" ? props.labelSize : String(props.labelSize)+"px";
			if ( props.labelColor ) this.state.labelStyle.color = props.labelColor;
		}
		const classes = ["SvgPie SvgProgress"];
		if ( this.state.sizeClass ) classes.push("Size"+this.state.sizeClass);
		if ( this.state.maskid ) classes.push("Masked");
		if ( this.props.className ) classes.push(this.props.className);
		this.state.className = classes.join(' ');
	}
	render() {
		return (
			<div
				className={this.state.className}
				data-value={this.state.label || this.state.value}
				title={this.props.title || this.state.label || this.props.value || ""}
				style={{
					width: String(this.state.size)+"px",
					height: String(this.state.size)+"px"
				}}
			>
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
					{ (!! this.state.maskid ) && this.state.mask }
					<circle
						className={"SvgProgressExternal"}
						r={ this.state.radius - this.state.border / 2}
						cx={this.state.radius}
						cy={this.state.radius}
						stroke={this.state.borderColor}
						strokeWidth={this.state.border}
						fill={this.state.bgColor}
						mask={ this.state.maskid ? `url(#${this.state.maskid})` : undefined }
					/>
					{ this.state.value > 0 && (
						<path
							className={"SvgProgressInternal"}
							fill={this.state.color}
							d={arcPathFromPercent(
								this.state.radius,
								this.state.radius,
								this.state.radius - this.state.border - this.state.borderOffset,
								this.state.value,
								this.state.startAngle
							)}
							mask={ this.state.maskid ? `url(#${this.state.maskid})` : undefined }
						/>
					)}
				</svg>
				{this.state.label && (
					<div
						className={"SvgLabel"}
						style={this.state.labelStyle}
					>
						{this.state.label}
					</div>
				)}
			</div>
		)
	}
}

export default SvgPieProgress;

/* © 2023 balestra@altersoftware.it */
