import {
	CardContent,
	Container,
	Grid,
	Typography,
	Card,
	CircularProgress,
	Alert,
	AlertTitle,
	Box,
} from "@mui/material";
import * as React from "react";
import {connect} from "react-redux";
import RemediationFullTable from "../Ui/RemediationFullTable";
import {Link} from "react-router-dom";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {setSelectStorico} from "../Reducer/AppReducer";
import InputAdornment from "@mui/material/InputAdornment";
import "../assets/css/Remediations.css";
import {getIcon} from "../Service/Shared";

const headCells = [
	{field: "id", label: "ID", numeric: false},
	{field: "titolo", label: "Titolo controllo", numeric: false},
	{field: "copertura", label: "Copertura", numeric: true},
	{field: "domande", label: "Remediation", numeric: false,},
];

const strategies = [
	"Nessuna strategia",
	"Minimum Coverage Strategy",
	"Greedy Coverage Strategy",
];

const difficulties = ["Tutte", "Non specificato", "Bassa", "Media", "Elevata"];

const costs = ["Tutti", "Non specificato", "Basso", "Medio", "Elevato"];

function Remediation(props) {
	const [strategia, setStrategia] = React.useState("Nessuna strategia");
	const [selectedCosto, setSelectedCosto] = React.useState("Tutti");
	const [selectedDifficolta, setSelectedDifficolta] = React.useState("Tutte");
	const [selectedStandard, setSelectedStandard] =
		React.useState("Tutti gli standard");
	const [standards, setStandards] = React.useState([]);

	const [searchQuery, setSearchQuery] = React.useState("");

	let inputs = props.dataPlatform?.platform?.inputs
		? [...props.dataPlatform?.platform?.inputs].reverse()
		: [];

	function selectStandard(inputs, storicoAssets) {
		if (inputs && inputs.length > 0) {
			const dateStorico = storicoAssets[0].toString();

			const selectedInput = inputs.filter((input) => {
				const dateInput = input.dataUltimaModifica.toString();
				return dateInput === dateStorico;
			});
			const filteredByDate = selectedInput[0];
			if (filteredByDate.controlli && filteredByDate.controlli.length > 0) {
				const standards = filteredByDate.controlli.map(
					(controllo) => controllo.titoloStandard
				);
				const allStandard = "Tutti gli standard";
				const orderedStandars = [...new Set(standards)];
				orderedStandars.unshift(allStandard);
				setStandards(orderedStandars);
			}
		}
	}

	const selectedStorico = (value) => {
		const currentIndex = props.selectStorico.indexOf(value);
		const newSelectList = [];

		if (currentIndex === -1) {
			newSelectList.push(value);
			props.setSelectStorico(newSelectList);
		}
	};

	React.useEffect(() => {
		if (props.dataPlatform) {
			let index = props.dataPlatform.platform?.inputs?.length - 1;
			selectedStorico(
				props.dataPlatform.platform?.inputs[index]?.dataCaricamento
			);
		}
	}, [props.dataPlatform]);

	React.useEffect(() => {
		if (props.dataPlatform && props.selectStorico) {
			selectStandard(props.dataPlatform?.platform?.inputs, props.selectStorico);
		}
	}, [props.selectStorico, props.dataPlatform]);

	return (
		<div>
			<div className="breadCrumbs">
				{ getIcon("Remediation") }
				<p>Remediation</p>
				{ getIcon("PathSeparator") }
				<p>Info e dettagli</p>
			</div>
			<Container
				maxWidth={false}
				className={"RemediationsPage"}
				sx={{
					padding: "24px",
					display: "flex !important",
					flexDirection: "row",
					justifyContent: "center",
					flexWrap: "wrap",
				}}
			>
				<Grid maxWidth={"lg"} container justifyContent={"center"} spacing={3}>
					<Grid key={1} item xs={12} md={12}>
						<Card elevation={6} sx={{height: "100%"}}>
							<CardContent>
								<div className={"MultiFilterBox"}>
									{!props.loading && standards.length > 2 && (
										<FormControl className={"boxField"}>
											<InputLabel id="demo-simple-select-autowidth-label">
												STANDARD
											</InputLabel>
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												value={selectedStandard}
												onChange={(e) => setSelectedStandard(e.target.value)}
												label="COPERTURA"
												defaultValue={selectStandard}
												sx={{
													padding: "0px!important",
													"& .MuiTypography-root": {
														fontWeight: 600,
														color: "#033361",
													},
													"& .MuiInputBase-input": {
														padding: "8px 16px",
													},
												}}
											>
												{standards.map((input, index) => {
													return (
														<MenuItem
															key={index}
															value={input}
															selected={input === selectedStandard}
															sx={{
																"&.MuiListItem-root.Mui-selected": {
																	bgcolor: "#ffffff21",
																	borderRight: "3px solid white",
																},
															}}
														>
															<ListItemText
																style={{
																	fontWeight: 600,
																	color: "#033661",
																}}
																primary={input}
															/>
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
									{!props.loading && strategia === "Nessuna strategia" && (
										<FormControl className={"boxField"}>
											<InputLabel id="demo-simple-select-autowidth-label">
												COSTO
											</InputLabel>
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												value={selectedCosto}
												onChange={(e) => setSelectedCosto(e.target.value)}
												label="COPERTURA"
												defaultValue={selectedCosto}
												sx={{
													padding: "0px!important",
													"& .MuiTypography-root": {
														fontWeight: 600,
														color: "#033361",
													},
													"& .MuiInputBase-input": {
														padding: "8px 16px",
													},
												}}
											>
												{costs.map((input, index) => {
													return (
														<MenuItem
															key={index}
															value={input}
															selected={input === selectedCosto}
															sx={{
																"&.MuiListItem-root.Mui-selected": {
																	bgcolor: "#ffffff21",
																	borderRight: "3px solid white",
																},
															}}
														>
															<ListItemText
																style={{
																	fontWeight: 600,
																	color: "#033661",
																}}
																primary={input}
															/>
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
									{!props.loading && strategia === "Nessuna strategia" && (
										<FormControl className={"boxField"}>
											<InputLabel id="demo-simple-select-autowidth-label">
												DIFFICOLTÀ
											</InputLabel>
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												value={selectedDifficolta}
												onChange={(e) => setSelectedDifficolta(e.target.value)}
												label="COPERTURA"
												defaultValue={selectedDifficolta}
												sx={{
													padding: "0px!important",
													"& .MuiTypography-root": {
														fontWeight: 600,
														color: "#033361",
													},
													"& .MuiInputBase-input": {
														padding: "8px 16px",
													},
												}}
											>
												{difficulties.map((input, index) => {
													return (
														<MenuItem
															key={index}
															value={input}
															selected={input === selectedDifficolta}
															sx={{
																"&.MuiListItem-root.Mui-selected": {
																	bgcolor: "#ffffff21",
																	borderRight: "3px solid white",
																},
															}}
														>
															<ListItemText
																style={{
																	fontWeight: 600,
																	color: "#033661",
																}}
																primary={input}
															/>
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
									{!props.loading &&
										props.dataPlatform != null &&
										props.dataPlatform?.platform?.inputs?.length > 0 && (
											<FormControl className={"boxField"}>
												<InputLabel id="demo-simple-select-autowidth-label">
													STORICO ASSESSMENT
												</InputLabel>
												<Select
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={props.selectStorico}
													onChange={(e) => selectedStorico(e.target.value)}
													label="STORICO ASSESSMENT"
													defaultValue={props.selectStorico}
													sx={{
														padding: "0px!important",
														"& .MuiTypography-root": {
															fontWeight: 600,
															color: "#033361",
														},
														"& .MuiInputBase-input": {
															padding: "8px 16px",
														},
													}}
												>
													{inputs.map((input, index) => {
														return (
															<MenuItem
																key={index}
																value={input.dataCaricamento}
																selected={
																	props.selectStorico.length > 0
																		? props.selectStorico.indexOf(
																		input.dataCaricamento
																	) !== -1
																		: index ===
																		props.dataPlatform.platform.inputs.length -
																		1
																}
																sx={{
																	"&.MuiListItem-root.Mui-selected": {
																		bgcolor: "#ffffff21",
																		borderRight: "3px solid white",
																	},
																}}
															>
																<ListItemText
																	style={{
																		fontWeight: 600,
																		color: "#033661",
																	}}
																	primary={input.dataCaricamento}
																/>
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										)}
									{!props.loading && (
										<>
											<FormControl className={"boxField"}>
												<InputLabel id="demo-simple-select-autowidth-label">
													STRATEGIA
												</InputLabel>
												<Select
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={strategia}
													onChange={(e) => setStrategia(e.target.value)}
													label="STRATEGIA"
													defaultValue={strategia}
													sx={{
														padding: "0px!important",
														"& .MuiTypography-root": {
															fontWeight: 600,
															color: "#033361",
														},
														"& .MuiInputBase-input": {
															padding: "8px 16px",
														},
													}}
												>
													{strategies.map((input, index) => {
														return (
															<MenuItem
																key={index}
																value={input}
																selected={input === strategia}
																sx={{
																	"&.MuiListItem-root.Mui-selected": {
																		bgcolor: "#ffffff21",
																		borderRight: "3px solid white",
																	},
																}}
															>
																<ListItemText
																	style={{
																		fontWeight: 600,
																		color: "#033661",
																	}}
																	primary={input}
																/>
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>

											<FormControl className={"canExpand"}>
												<TextField
													id="outlined-basic"
													label="RICERCA PER TITOLO"
													variant="outlined"
													value={searchQuery}
													className={"boxField"}
													onChange={(e) => setSearchQuery(e.target.value)}
													InputProps={{
														startAdornment: (
															<InputAdornment position="end">
																<SearchIcon/>
															</InputAdornment>
														),
													}}
													sx={{
														padding: "0px!important",
														"& .MuiTypography-root": {
															fontWeight: 600,
															color: "#033361",
														},
														"& .MuiInputBase-input": {
															padding: "12px 16px",
														},
													}}
												/>
											</FormControl>
										</>
									)}
								</div>
								<Grid>
									{strategia === "Minimum Coverage Strategy" && (
										<div className="typeStrategy">
											<p>
												Strategia per la visualizzazione di Remediation in cui
												viene assegnata maggiore priorità alle scoperture che sono
												più lontani dalla completa implementazione.
											</p>
										</div>
									)}
									{strategia === "Greedy Coverage Strategy" && (
										<div className="typeStrategy">
											<p>
												Strategia per la visualizzazione di Remediation in cui
												viene assegnata maggiore priorità alle scoperture che sono
												più vicine alla completa implementazione.
											</p>
										</div>
									)}
								</Grid>
								<Grid className="titoloSezioneCenter">
									<Typography
										sx={{
											display: "flex",
											fontWeight: "600",
											alignItems: "center",
											marginBottom: "8px !important",
											fontSize: "20px",
											color: "#033661",
											marginTop: "8px",
										}}
										component={"p"}
									>
										{strategia}
									</Typography>
								</Grid>
								<Grid container justifyContent={"center"}>
									{props.loading ? (
										<CircularProgress/>
									) : props.dataPlatform != null &&
									props.dataPlatform?.platform?.inputs?.length > 0 &&
									props.dataPlatform?.platform?.inputs ? (
										<>
											{strategia !== "Nessun filtro" ? (
												<RemediationFullTable
													strategia={strategia}
													selectedStorico={props.selectedStorico}
													inputs={props.dataPlatform?.platform?.inputs}
													topFiveRemediation={props.topFiveRemediation}
													standard={selectedStandard}
													headCells={headCells}
													searchQuery={searchQuery}
													difficolta={selectedDifficolta}
													costo={selectedCosto}
												/>
											) : (
												<Grid>
													<Box sx={{padding: "16px"}}>
														<Alert severity="info">
															<AlertTitle>Info</AlertTitle>
															Seleziona una <b>strategia </b> da applicare
														</Alert>
													</Box>
												</Grid>
											)}
										</>
									) : (
										<Grid>
											<Box sx={{padding: "16px"}}>
												<Alert severity="warning">
													<AlertTitle>Avviso</AlertTitle>
													Caricare almeno un documento valido
												</Alert>
											</Box>
										</Grid>
									)}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		loading: state.appReducer.loading,
		upload: state.appReducer.upload,
		dataPlatform: state.appReducer.dataPlatform,
		utente: state.authReducer.utente,
		selectStorico: state.appReducer.selectStorico,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setSelectStorico: (flag) => dispatch(setSelectStorico(flag)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Remediation);
