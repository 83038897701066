import React, {useRef} from "react";
import {
	CardContent,
	Container,
	Grid,
	Typography,
	Card,
	CircularProgress,
	Alert,
	AlertTitle,
	Box,
	Button,
} from "@mui/material";
import {connect} from "react-redux";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {setSelectStorico} from "../Reducer/AppReducer";
import ReportTable from "../Ui/ReportTable";
import RemediationTable from "../Ui/RemediationTable";
import ReportTablePlus from "../Ui/ReportTablePlus";
import ReactToPrint from "react-to-print";
import {getIcon} from "../Service/Shared";

const headCellsReport = [
	{field: "titolo", label: "Titolo controllo", numeric: false},
	{field: "copertura", label: "Copertura", numeric: false},
	{
		field: "domande",
		label: "Maturità",
		numeric: false,
	},
];

const headCellsReportPlus = [
	{field: "titolo", label: "Titolo controllo", numeric: false},
	{field: "copertura", label: "Completamento", numeric: false},
	{
		field: "domande",
		label: "Maturità",
		numeric: false,
	},
];

const headCellsRemediation = [
	{field: "id", label: "ID", numeric: false},

	{field: "testoDomanda", label: "Domanda", numeric: false},
	{field: "testoRisposta", label: "Remediation", numeric: true},
	{
		field: "costo",
		label: "Costo",
		numeric: false,
	},
	{
		field: "difficolta",
		label: "Difficoltà",
		numeric: false,
	},
];

const modalitaData = [
	"Controlli e radar",
	"Solo remediation",
	"Controlli, status di completamento e maturità",
];

function Report(props) {
	const [loading, setLoading] = React.useState(false);
	const [reportActive, setReportActive] = React.useState(false);
	const [selectedModalita, setSelectedModalita] =
		React.useState("Controlli e radar");
	const [modalita, setModalita] = React.useState(modalitaData);
	const [strategia, setStrategia] = React.useState("Nessuna strategia");
	const [selectedCosto, setSelectedCosto] = React.useState("Tutti");
	const [selectedDifficolta, setSelectedDifficolta] = React.useState("Tutte");
	const [selectedStandard, setSelectedStandard] =
		React.useState("Tutti gli standard");

	const [searchQuery, setSearchQuery] = React.useState("");
	const componentRef = useRef();

	let inputs = props.dataPlatform?.platform?.inputs
		? [...props.dataPlatform?.platform?.inputs].reverse()
		: [];

	const selectedStorico = (value) => {
		const currentIndex = props.selectStorico.indexOf(value);
		const newSelectList = [];

		if (currentIndex === -1) {
			newSelectList.push(value);
			props.setSelectStorico(newSelectList);
		}
	};

	function generaReport() {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			setReportActive(true);
		}, 2000);
	}

	React.useEffect(() => {
		setLoading(true);
		if (props.dataPlatform) {
			let index = props.dataPlatform.platform?.inputs?.length - 1;
			selectedStorico(
				props.dataPlatform.platform?.inputs[index]?.dataCaricamento
			);
			setLoading(false);
		}
	}, [props.dataPlatform]);

	return (
		<div>
			<div className="breadCrumbs">
				{ getIcon("Report") }
				<p>Report</p>
			</div>
			<Container
				maxWidth={false}
				sx={{
					padding: "24px",
					display: "flex !important",
					flexDirection: "row",
					justifyContent: "center",
					flexWrap: "wrap",
				}}
			>
				{reportActive && (
					<ReactToPrint
						trigger={() => (
							<label
								style={{marginBottom: "16px"}}
								htmlFor="contained-button-file"
							>
								<Button
									className="buttonUpload"
									variant="contained"
									component="span"
								>
									Genera Report
								</Button>
							</label>
						)}
						content={() => componentRef.current}
					/>
				)}
				{!reportActive ? (
					<Grid maxWidth={"sm"} container justifyContent={"center"} spacing={3}>
						<Grid key={1} item xs={12} md={12}>
							<Card elevation={6} sx={{height: "100%"}}>
								<CardContent>
									<Grid className="titoloSezioneCenter">
										<Typography
											sx={{
												display: "flex",
												fontWeight: "600",
												alignItems: "center",
												marginBottom: "8px !important",
												fontSize: "20px",
												color: "#033661",
											}}
											component={"p"}
										>
											Genera report{" "}
										</Typography>
									</Grid>
									<Box
										encType="multipart/form-data"
										sx={{
											"& .MuiTextField-root": {m: 1, width: {xs: "45ch"}},
											"& .MuiButton-root": {
												m: 1,
												color: "#033661",
												bgcolor: "#f0f0f0",
												"&:hover": {backgroundColor: "#033661", color: "#fff"},
												"&.Mui-disabled": {
													bgcolor: "transparent",
													color: "#00000042 !important",
												},
											},
										}}
										noValidate
										autoComplete="off"
									>
										<Grid container>
											<Grid
												justifyContent={"center"}
												textAlign={"center"}
												item
												xs={12}
											>
												{loading && !reportActive ? (
													<CircularProgress/>
												) : (
													<>
														<Box>
															<FormControl
																fullWidth
																sx={{
																	m: 1,
																	marginTop: "24px",
																	maxWidth: "300px",
																	justifyContent: "center",
																	textAlign: "center"
																}}
															>
																<InputLabel
																	id="demo-simple-select-autowidth-label"
																	style={{color: "#033361"}}
																>
																	Storico assessment
																</InputLabel>
																<Select
																	labelId="demo-simple-select-autowidth-label"
																	id="demo-simple-select-autowidth"
																	value={props.selectStorico}
																	onChange={(e) =>
																		selectedStorico(e.target.value)
																	}
																	label="STORICO ASSESSMENT"
																	defaultValue={props.selectStorico}
																	sx={{
																		padding: "0px!important",
																		"& .MuiTypography-root": {
																			fontWeight: 600,
																			color: "#033361",
																		},
																		"& .MuiInputBase-input": {
																			padding: "8px 16px",
																		},
																	}}
																>
																	{inputs.map((input, index) => {
																		return (
																			<MenuItem
																				key={index}
																				value={input.dataCaricamento}
																				selected={
																					props.selectStorico.length > 0
																						? props.selectStorico.indexOf(
																						input.dataCaricamento
																					) !== -1
																						: index ===
																						props.dataPlatform.platform.inputs
																							.length -
																						1
																				}
																				sx={{
																					"&.MuiListItem-root.Mui-selected": {
																						bgcolor: "#ffffff21",
																						borderRight: "3px solid white",
																					},
																				}}
																			>
																				<ListItemText
																					style={{
																						fontWeight: 600,
																						color: "#033661",
																					}}
																					primary={input.dataCaricamento}
																				/>
																			</MenuItem>
																		);
																	})}
																</Select>
															</FormControl>

															<FormControl
																justifyContent={"center"}
																textAlign={"center"}
																fullWidth
																sx={{
																	m: 1,
																	marginTop: "16px",
																	width: "400px",
																}}
															>
																<InputLabel
																	id="demo-simple-select-autowidth-label"
																	style={{color: "#033361"}}
																>
																	Modalità
																</InputLabel>
																<Select
																	labelId="demo-simple-select-autowidth-label"
																	id="demo-simple-select-autowidth"
																	value={selectedModalita}
																	onChange={(e) =>
																		setSelectedModalita(e.target.value)
																	}
																	label="Modalità"
																	defaultValue={selectedModalita}
																	sx={{
																		padding: "0px!important",
																		"& .MuiTypography-root": {
																			fontWeight: 600,
																			color: "#033361",
																		},
																		"& .MuiInputBase-input": {
																			padding: "8px 16px",
																		},
																	}}
																>
																	{modalita.map((input, index) => {
																		return (
																			<MenuItem
																				key={index}
																				value={input}
																				selected={input === selectedModalita}
																				sx={{
																					"&.MuiListItem-root.Mui-selected": {
																						bgcolor: "#ffffff21",
																						borderRight: "3px solid white",
																					},
																				}}
																			>
																				<ListItemText
																					style={{
																						fontWeight: 600,
																						color: "#033661",
																					}}
																					primary={input}
																				/>
																			</MenuItem>
																		);
																	})}
																</Select>
															</FormControl>
														</Box>
														<label htmlFor="contained-button-file">
															<Button
																className="buttonUpload"
																variant="contained"
																component="span"
																onClick={() => generaReport()}
															>
																Genera
															</Button>
														</label>
													</>
												)}
											</Grid>
										</Grid>
									</Box>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				) : (
					<>
						<Grid
							ref={componentRef}
							id="report-print"
							maxWidth={"lg"}
							container
							justifyContent={"center"}
							spacing={3}
						>
							<Grid key={1} item xs={12} md={12}>
								<Card elevation={6} sx={{height: "100%"}}>
									<CardContent>
										<Grid className="titoloSezioneCenter">
											<Typography
												sx={{
													display: "flex",
													fontWeight: "600",
													alignItems: "center",
													marginBottom: "8px !important",
													fontSize: "20px",
													color: "#033661",
												}}
												component={"p"}
											>
												Report riferito a storico {props.selectStorico}
											</Typography>
										</Grid>
										{props.loading ? (
											<CircularProgress/>
										) : props.dataPlatform != null &&
										props.dataPlatform?.platform?.inputs?.length > 0 &&
										props.dataPlatform?.platform?.inputs ? (
											<>
												{selectedModalita === "Controlli e radar" && (
													<ReportTable
														selectedStorico={props.selectStorico}
														inputs={props.dataPlatform?.platform?.inputs}
														topFiveRemediation={props.topFiveRemediation}
														headCells={headCellsReport}
													/>
												)}
												{selectedModalita === "Solo remediation" && (
													<RemediationTable
														searchQuery={searchQuery}
														difficolta={selectedDifficolta}
														costo={selectedCosto}
														strategia={strategia}
														standard={selectedStandard}
														selectedStorico={props.selectStorico}
														inputs={props.dataPlatform?.platform?.inputs}
														topFiveRemediation={props.topFiveRemediation}
														headCells={headCellsRemediation}
													></RemediationTable>
												)}
												{selectedModalita ===
													"Controlli, status di completamento e maturità" && (
														<ReportTablePlus
															selectedStorico={props.selectStorico}
															inputs={props.dataPlatform?.platform?.inputs}
															topFiveRemediation={props.topFiveRemediation}
															headCells={headCellsReportPlus}
														/>
													)}
											</>
										) : (
											<Grid>
												<Box sx={{padding: "16px"}}>
													<Alert severity="warning">
														<AlertTitle>Avviso</AlertTitle>
														Errore nella generazione del report
													</Alert>
												</Box>
											</Grid>
										)}
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</>
				)}
			</Container>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		loading: state.appReducer.loading,
		upload: state.appReducer.upload,
		dataPlatform: state.appReducer.dataPlatform,
		utente: state.authReducer.utente,
		selectStorico: state.appReducer.selectStorico,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setSelectStorico: (flag) => dispatch(setSelectStorico(flag)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Report);
