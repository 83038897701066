import React from "react";
import "./MySvg.css";
import {weightFromCopertura, weightFromMaturita} from "../../Service/Shared";

const SvgBulbProgress = class extends React.Component {
	constructor(props = {}) {
		super(props);
		this.viewWidth = 260;
		this.viewHeight = 30;
		this.state = {
			border: props.border ?? 1,
			value: props.value || 0,
			bulbs: props.bulbs || 5,
			max: props.max || 100,
			label: props.label || true,
			width: props.width || this.viewWidth
		};
		if ( props.height ) {
			this.viewHeight = props.height * this.viewWidth /this.state.width;
		}
		if (this.state.max !== 100) this.state.perc = this.state.value * 100 / this.state.max;
		else this.state.perc = this.state.value;
		if (this.state.label === true) this.state.label = this.state.value;
		const classes = ["SvgBulbs SvgProgress"];
		if (this.props.className) classes.push(this.props.className);
		this.state.className = classes.join(' ');
		this.bulbs = this.bulbs.bind(this);
		if (props.maturita || props.copertura) {
			this.state.bulbs = props.bulbs || 17;
			let mc;
			if (props.maturita) {
				this.state.label = props.maturita;
				if (this.state.label === "Ottimizzazione") this.state.label = "Ottimizzato";
				mc = weightFromMaturita(this.state.label);
			} else {
				this.state.label = props.copertura;
				mc = weightFromCopertura(this.state.label);
			}
			this.state.className += " " + mc;
			const firstStep = 100 / this.state.bulbs;
			const moreSteps = (100 - firstStep) / 4;
			switch (mc) {
				case "weighted-danger":
					this.state.perc = firstStep -.1;
					break;
				case "weighted-low":
					this.state.perc = firstStep + moreSteps - .1;
					break;
				case "weighted-medium":
					this.state.perc = firstStep + 2 * moreSteps - .1;
					break;
				case "weighted-high":
					this.state.perc = firstStep + 3 * moreSteps - .1;
					break;
				case "weighted-max":
					this.state.perc = 100;
					break;
				default:
					this.state.perc = 0;
			}
		}
		this.stepwidth = 100 / this.state.bulbs;
		this.marginX = (this.state.bulbs > 5 ? 8 : 20);
		this.bulbsSpace = (this.viewWidth - (this.marginX * 2)) / this.state.bulbs;
		this.bulbSize = this.bulbsSpace * (this.state.bulbs > 5 ? .9 : .75);
		this.bulbOffestX = (this.bulbsSpace - this.bulbSize) /2;
		this.deltaX = (this.state.border||0)/2 + this.marginX + this.bulbOffestX;
		this.bulbTy = this.state.width / 20;
		this.bulbSizeY = this.viewHeight - this.bulbTy * 2;
		this.bulbCy = this.bulbTy + this.bulbsSpace / 2;
		this.bulbShape = props.bulbShape || ( this.state.bulbs < 10 ? "circle" : "rect");
		if ( this.bulbShape === "circle" ) {
			this.bulbRadius = Math.min(this.bulbSize / 2,this.bulbSizeY);
		} else {
			this.bulbBR = this.state.bulbs > 10 ? 3 : 6;
		}
	}

	bulbs() {
		const out = [];
		if ( this.bulbShape === "circle" ) {
			for (let i = 0; i < this.state.bulbs; i++) {
				const cx = this.deltaX + (i + .5) * this.bulbsSpace;
				const min = i * this.stepwidth;
				out.push(
					<circle
						key={i}
						className={"SvgProgressInternal " + (this.state.perc > min ? "On" : "Off")}
						r={this.bulbRadius}
						cx={cx}
						cy={this.bulbCy}
						strokeWidth={this.state.border || undefined}
					/>
				);
			}
		} else {
			for (let i = 0; i < this.state.bulbs; i++) {
				const min = i * this.stepwidth;
				out.push(
					<rect
						key={i}
						className={"SvgProgressInternal " + (this.state.perc > min ? "On" : "Off")}
						x={ this.deltaX + (i * this.bulbsSpace) }
						y={this.bulbTy}
						width={this.bulbSize}
						height={this.bulbSizeY}
						rx={this.bulbBR}
						strokeWidth={this.state.border || undefined}
					/>
				);
			}
		}
		return out;
	};

	render() {
		return (
			<div
				className={this.state.className}
				style={{ width: String(this.state.width) + "px" }}
				title={this.state.title||this.state.label||undefined}
			>
				<svg
					viewBox={`0 0 ${this.viewWidth} ${this.viewHeight}`}
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
				>
					{(!!this.state.border) && (
						<rect
							className={"SvgProgressExternal"}
							fill={this.state.bgColor || undefined}
							stroke={this.state.borderColor || "#000"}
							strokeWidth={this.state.border}
							x={0}
							y={0}
							width={this.viewWidth}
							height={this.viewHeight}
							rx={12}
						/>
					)}
					{this.bulbs()}
					<desc>
						{this.state.title||this.state.label||""}
					</desc>
				</svg>
				{this.state.label && (
					<div className={"SvgLabel"}>
						{this.state.label}
					</div>
				)}
			</div>
		)
	}
}

export default SvgBulbProgress;

/* © 2023 balestra@altersoftware.it */
