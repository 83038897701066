import * as type from "./types";
import instance from "../../Service/Api";
import { SET_ERROR } from "../AppReducer/types";

function getLocalAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
}

export const setLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("dp-user");
    dispatch({
      type: type.SET_LOGOUT,
      token: localStorage.getItem("accessToken"),
    });
  };
};

export const refreshToken = (accessToken, flag) => {
  return (dispatch) => {
    dispatch({
      type: type.REFRESH_TOKEN,
      token: accessToken,
      refreshToken: flag,
    });
  };
};

export const setLogin = (data) => {
  return async (dispatch) => {
    const res = await signIn(dispatch, data);
    if (res.data) {
      const { token, refreshToken } = res.data;
      localStorage.setItem("accessToken", token);
      localStorage.setItem("dp-user", JSON.stringify(res.data));
      localStorage.setItem("refreshToken", refreshToken);
      return dispatch({
        type: type.SET_LOGIN,
        utente: res.data,
        token: getLocalAccessToken(),
      });
    }
  };
};

async function signIn(dispatch, data) {
  try {
    return await instance.post("/security/login", {
      username: data.username,
      password: data.password,
    });
  } catch {
    return dispatch({
      type: SET_ERROR,
      error: true,
      message: "Username o Password non validi",
    });
  }
}
