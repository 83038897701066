import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {IconButton, Paper} from "@mui/material";
import {getIcon} from "../Service/Shared";


const Transition = React.forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});

const value2color = (v = "") => "#" + (v.startsWith("Bass") ? "048e4b" : v.startsWith("Medi") ? "b88c20" : "a80303") + " !important";

function filterDomandeByCosto(domande, costo) {
	let resultDomande = [];

	if (domande && domande.length > 0) {
		if (costo === "Tutti") resultDomande = domande;
		else {
			domande.forEach((domanda) => {
				if (costo === "Non specificato") {
					if (domanda.remediation.costo === "") {
						resultDomande.push(domanda);
					}
				} else {
					if (domanda.remediation.costo === costo) {
						resultDomande.push(domanda);
					}
				}
			});
		}
	}

	return resultDomande;
}

function filterDomandeByDifficolta(domande, difficolta) {
	let resultDomande = [];

	if (domande && domande.length > 0) {
		if (difficolta === "Tutte") resultDomande = domande;
		else {
			domande.forEach((domanda) => {
				if (difficolta === "Non specificato") {
					if (domanda.remediation.difficolta === "") {
						resultDomande.push(domanda);
					}
				} else {
					if (domanda.remediation.difficolta === difficolta) {
						resultDomande.push(domanda);
					}
				}
			});
		}
	}

	return resultDomande;
}

export default function RemediationDetailsDomandeModal(props) {
	let domande = filterDomandeByCosto(props.content?.domande, props.costo);
	domande = filterDomandeByDifficolta(props.content?.domande, props.difficolta);
	return (
		<Dialog
			className={"modalDialog"}
			maxWidth="md"
			fullWidth
			sx={{m: 10, p: 2}}
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-describedby={props.title}
		>
			{" "}
			<DialogTitle elevation={2} component={Paper} className={"modalTitle"}>
				{ getIcon("Remediation") }
				{props.title?.toUpperCase()}
				<IconButton
					focusRipple
					onClick={props.handleClose}
					aria-label="close"
				>
					{ getIcon("Close") }
				</IconButton>
			</DialogTitle>
			<DialogContent className={"modalBody"}>
				{domande?.map((domanda, index) => {
					return (
						<div className={"modalRow"}>
							<div className={"modalRowText"}>
								{domanda?.testo && <div className={"question"}>{domanda.testo}</div>}
								{domanda?.remediation?.testo && <div className={"answer"}>{domanda.remediation.testo}</div>}
							</div>
							<div className={"modalRowControls"}>
								<div className={"control"}>
									<label>Costo:</label>
									<span className={"weighted" + (domanda?.remediation?.costo ?? "").substring(0, 3)}>
											{domanda?.remediation?.costo || "—"}
										</span>
								</div>
								<div className={"control"}>
									<label>Difficoltà:</label>
									<span className={"weighted" + (domanda?.remediation?.difficolta ?? "").substring(0, 3)}>
											{domanda?.remediation?.difficolta || "—"}
										</span>
								</div>
							</div>
						</div>
					);
				})}
			</DialogContent>
		</Dialog>
	);
}
