/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
	circularProgressClasses,
} from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {cleanPercentage} from "../../Service/Shared";

function CircularProgressWithLabel(props) {
	return (
		<Box
			sx={{
				position: "relative",
				transform: "scale(0.8)",
				display: "inline-flex",
			}}
		>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typography
					variant="caption"
					component="div"
					color={props.colorlabel || "white"}
				>
					{cleanPercentage(props.value, 0)}
				</Typography>
			</Box>
		</Box>
	);
}

function CircularProgressWithLabelVariant(props) {
	const size = props?.size || 40;
	const labelSize = Math.min(size / 4, 12);
	const thick = 2 + size / (props.colorlabel ? 20 : 2);
	return (
		<Box sx={{
			position: "relative",
			display: "inline-block",
			width:size+"px",
			height:size+"px"
		}}>
			<CircularProgress
				variant="determinate"
				sx={{
					color: (theme) =>
						theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
					position: "absolute",
					left: 0,
					top: 0
				}}
				size={size}
				thickness={thick}
				{...props}
				value={100}
			/>
			<CircularProgress
				variant="determinate"
				sx={{
					color: (theme) =>
						theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
					animationDuration: "550ms",
					position: "absolute",
					left: 0,
					top: 0,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: "round",
					},
				}}
				size={size}
				thickness={thick}
				{...props}
			/>
			{props.colorlabel && (
				<Box
					sx={{
						top: `${Math.round(.5 * (size - labelSize))}px`,
						left: "0",
						right: "0",
						textAlign: "center",
						bottom: 0,
						position: "absolute",
					}}
				>
					<Typography
						variant="caption"
						component="div"
						sx={{fontSize: Math.round(labelSize)+"px", lineHeight: Math.round(labelSize)+"px"}}
						color={props.colorlabel || "white"}
					>
						{cleanPercentage(props.value, 0)}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

CircularProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
	colorlabel: PropTypes.string,
	size: PropTypes.string,
};

export default function CircularStatic(progress, color, size) {
	const valueNumber = parseFloat(progress);

	return (
		<CircularProgressWithLabelVariant
			value={valueNumber}
			colorlabel={color}
			size={size}
		/>
	);
}
