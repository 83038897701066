import * as type from './types'

const accessToken = localStorage.getItem('accessToken');
const utente = localStorage.getItem('dp-user');

const initialState = {
    utente: utente? JSON.parse(utente) : null,
    token: accessToken? accessToken : null,
    refreshToken: false,
}

export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case type.SET_LOGIN:
            return Object.assign({}, state, {
                utente: action.utente,
                token: action.token,
            });
        case type.SET_LOGOUT:
            return Object.assign({}, state, {
                token: action.token
            });
        case type.REFRESH_TOKEN:
            return Object.assign({}, state, {
                token: action.token,
                refreshToken: action.refreshToken
            });

        default: return state;
    }
}