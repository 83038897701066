import * as React from "react";
import {styled} from "@mui/material/styles";
import {
	Box,
	Container,
	Paper,
	Grid,
	Typography,
	Button,
	Alert,
	AlertTitle,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
} from "@mui/material";

import CustomTextField from "../Ui/CustomTextField";
import {connect} from "react-redux";
import {
	uploadFile,
	setError,
	setSuccess,
	setErrorDetail,
} from "../Reducer/AppReducer";
import {getIcon} from "../Service/Shared";

const Input = styled("input")({
	display: "none",
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
	"& .MuiPaper-root-MuiDialog-paper": {
		minWidth: "480px",
	},
}));

const BootstrapDialogTitle = (props) => {
	const {children, onClose, ...other} = props;

	return (
		<DialogTitle sx={{m: 0, p: 2}} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					{ getIcon("Close") }
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

function Upload(props) {
	const initialState = {
		tipologia: "",
		username: props.utente.username,
		applicativo: "dp",
		allegato: null,
	};
	const [state, setState] = React.useState(initialState);

	const handleModalClose = () => {
		props.setErrorDetail("");
	};

	const selectFile = (event) => {
		if (event.target.files?.length > 0) {
			setState({
				...state,
				allegato: event.target.files,
			});
		}
	};
	const handleSubmit = (e) => {
		e?.preventDefault();
		const formData = new FormData();
		if ( ! (state.allegato && state.allegato[0]) ) return;
		formData.append("uploadfile", state.allegato[0]);
		formData.append("username", state.username);
		formData.append("applicativo", state.applicativo);
		props.uploadFile(formData, props.utente.username);
		setState(initialState);
	};
	return (
		<div>
			<div className="breadCrumbs">
				{ getIcon("Upload") }
				<p>Upload assessment</p>
			</div>
			<Container
				maxWidth={false}
				sx={{
					padding: "24px",
					display: "flex !important",
					flexDirection: "column",
					justifyContent: "center",
					flexWrap: "wrap",
				}}
			>
				<Container maxWidth="md" sx={{padding: "2rem"}}>
					<Paper elevation={6}>
						<Grid padding={"2rem"} className="titoloSezioneCenter">
							<Typography
								sx={{
									display: "flex",
									fontWeight: "600",
									alignItems: "center",
									marginBottom: "8px !important",
									fontSize: "20px",
									color: "#033661",
								}}
								component={"p"}
							>
								Upload assessment
							</Typography>
						</Grid>
						<Grid
							container
							justifyContent={"center"}
							textAlign={"center"}
							sx={{paddingBottom: "1rem"}}
						>
							<Box
								component="form"
								encType="multipart/form-data"
								sx={{
									"& .MuiTextField-root": {
										m: 1, width: {xs: "45ch"},
										'& .MuiFilledInput-root': {overflow: 'visible', height: '3.5rem'}
									},
									"& .MuiButton-root": {
										m: 1,
										color: "#033661",
										bgcolor: "#f0f0f0",
										"&:hover": {backgroundColor: "#033661", color: "#fff"},
										"&.Mui-disabled": {
											bgcolor: "transparent",
											color: "#00000042 !important",
										},
									},
								}}
								noValidate
								autoComplete="off"
								onSubmit={(e) => {
									handleSubmit(e);
								}}
							>
								<Grid container></Grid>
								<Grid container>
									<Grid item xs={12}>
										<label htmlFor="contained-button-file">
											<Input
												onChange={(e) => selectFile(e)}
												accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
												id="contained-button-file"
												type="file"
											/>
											<Button
												className="buttonUpload"
												variant="contained"
												component="span"
											>
												Scegli il file
											</Button>
										</label>
										<CustomTextField
											required
											disabled
											label={
												state.allegato ? "Documento" : "Carica un documento excel"
											}
											value={state.allegato ? state.allegato[0]?.name : ""}
											variant="filled"
											onChange={(e) => selectFile(e)}
											onKeyPress={(e) => {
												e.code === "Enter" && handleSubmit();
											}}
										/>
									</Grid>
								</Grid>
								<div>
									<Button
										variant="contained"
										type="submit"
										disableFocusRipple
										disableRipple
										disableTouchRipple
									>
										Invia
									</Button>
								</div>
								{props.errorDetail && (
									<BootstrapDialog
										onClose={handleModalClose}
										aria-labelledby="customized-dialog-title"
										open={props.errorDetail.length > 0}
									>
										<BootstrapDialogTitle
											id="customized-dialog-title"
											onClose={handleModalClose}
										>
											Errore
										</BootstrapDialogTitle>
										<DialogContent dividers>
											<Alert
												sx={{
													margin: "0",
													maxHeight: "300px",
													whiteSpace: "pre-wrap",
													textAlign: "left",
													overflowY: "auto",
													paddingRight: "8px",
													background: "white",
													minWidth: "440px",
												}}
												severity="warning"
											>
												<AlertTitle></AlertTitle>
												<span>
                      {props.errorDetail
						  .replace(/^\s*/, "")
						  .replace(/\s*$/, "")}
                    </span>
											</Alert>
										</DialogContent>
										<DialogActions>
											<Button autoFocus onClick={handleModalClose}>
												Chiudi
											</Button>
										</DialogActions>
									</BootstrapDialog>
								)}
							</Box>
						</Grid>
					</Paper>
				</Container>
			</Container>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		error: state.appReducer.error,
		success: state.appReducer.success,
		utente: state.authReducer.utente,
		errorDetail: state.appReducer.errorDetail,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		uploadFile: (data, username) => {
			dispatch(uploadFile(data, username));
		},
		setError: (data) => {
			dispatch(setError(data));
		},
		setSuccess: (data) => {
			dispatch(setSuccess(data));
		},
		setErrorDetail: (data) => {
			dispatch(setErrorDetail(data));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Upload);
