import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {cleanPercentage, countDomandeCopertura, countDomandeMaturita, getMinMaturita, weightFromPercentage} from "../Service/Shared";
import {Link} from "react-router-dom";
import PieCoperturaSvgSmall from "../Components/Common/PieCoperturaSvgSmall";
import SvgBulbProgress from "../Custom/MySVG/SvgBulbProgress";
import PieMaturitaSvgSmall from "../Components/Common/PieMaturitaSvgSmall";

function getTablesData(inputs, storicoAssets, copertura, selectedStandard) {
	let arrayResult = [];
	const dateStorico = storicoAssets[0].toString();
	if ( selectedStandard.startsWith("Tutti gli")) selectedStandard = false;
	if ( copertura ) {
		switch (copertura) {
			case "<25%": copertura = [0,24.9999999]; break;
			case "tra 25% e 50%": copertura = [25,50]; break;
			case ">50%": copertura = [50.000000001,100]; break;
			case "tra 50% e 75%" : copertura = [50,75]; break;
			case "tra 75% e 100%" : copertura = [75,100]; break;
			default: copertura=false;
		}
	}
	const selectedInput = inputs.filter((input) => {
		const dateInput = input.dataUltimaModifica.toString();
		return dateInput === dateStorico;
	});
	selectedInput[0].controlli.forEach((controllo) => {
		if ( selectedStandard && controllo.titoloStandard !== selectedStandard ) return;
		if ( copertura ) {
			const c = parseFloat(controllo.copertura)
			if ( c < copertura[0]) return;
			if ( c > copertura[1]) return;
		}
		arrayResult.push({
			id: controllo.idControllo,
			name: controllo.controlloRif,
			standard: controllo.titoloStandard,
			percentage: controllo.copertura,
			parent: controllo.gerarchie.slice(-1)[0],
			maturita: getMinMaturita(controllo.domande),
			domande : controllo.domande
		});
	});
	return arrayResult;
}

function EnhancedTableHead(props) {
	const headCells = props.headCells;
	const {order, orderBy, onRequestSort} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow
				sx={{
					"& th": {
						color: "#033661",
						fontWeight: 600,
						"& .MuiTableSortLabel-root.Mui-active": {
							color: "#003f9d!important",
						},
						"&.MuiTableCell-root": {padding: "8px !important"},
					},
				}}
			>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.field}
						align={"center"}
						sx={{whiteSpace: "nowrap"}}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.field ? order : false}
					>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function DashboardTable(props) {
	const inputs = props.inputs;
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("costo");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const rowsPerPageOptions = [5, 10, 25, 50, 100];
	const rows = getTablesData(
		inputs,
		props.selectedStorico,
		props.copertura,
		props.selectedStandard
	);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = 0;

	return (
		<Box sx={{width: "100%"}}>
			<TableContainer>
				<Table
					sx={{minWidth: 750}}
					aria-labelledby="tableTitle"
					size={"medium"}
				>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows?.length}
						headCells={props.headCells}
					/>
					<TableBody
						sx={{"& td": {color: "#033661", padding: "4px 8px 0 8px"}}}
					>
						{rows
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							?.map((row, index) => {

								return (
									<TableRow key={row.name}>
										<TableCell sx={{width: "5%",whiteSpace:"pre"}}>
											{row.name.replace(/ .*/, '')}
										</TableCell>
										<TableCell sx={{width: "85%",fontWeight:"bold"}}>
											<Link
												key={row.parent}
												className={"darkColor"}
												style={{textDecoration: "none"}}
												to={ "/controllo-" + row.parent.replace(/ .+$/,'') }
											>
												<b
													onClick={()=>{ props.selectControllo(row.parent); } }
												>
													{row.name.replace(/^[^ ]+ /, '')}
												</b>
											</Link>
										</TableCell>
										<TableCell sx={{width: "5%"}} title={cleanPercentage(row.percentage)} align={"center"}>
											<SvgBulbProgress
												width={120}
												className={ weightFromPercentage(row.percentage) }
												bulbs={17}
												value={parseFloat(row.percentage)}
												label={cleanPercentage(row.percentage)}
											/>
										</TableCell>
										<TableCell align="center" sx={{width: "5%"}}>
											<SvgBulbProgress width={120} maturita={row.maturita} />
										</TableCell>
										<TableCell align="center" sx={{width: "5%"}} key={row.name+"piedomande"}>
											<PieCoperturaSvgSmall size={52} hole={.5} data={countDomandeCopertura(row.domande)} />
										</TableCell>
										<TableCell align="center" sx={{width: "5%"}} key={row.name+"piematurita"}>
											<PieMaturitaSvgSmall size={52} hole={.5} data={countDomandeMaturita(row.domande)} />
										</TableCell>
									</TableRow>
								);
							})}
						{ emptyRows > 0 && (
							<TableRow style={{height: 53 * emptyRows}}>
								<TableCell colSpan={6}/>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={rowsPerPageOptions}
				component="div"
				count={rows?.length}
				page={page}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				onPageChange={handleChangePage}
				labelRowsPerPage={"Righe per pagina"}
				labelDisplayedRows={({from, to, count}) => {
					return `${from}–${to} di ${count !== -1 ? count : `più di ${to}`}`;
				}}
				sx={{"& .MuiTablePagination-toolbar": {alignItems: "baseline"}}}
			/>
		</Box>
	);
}

export default DashboardTable;
