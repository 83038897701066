import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Slide,
	Typography,
} from "@mui/material";
import {Close as CloseIcon, BuildCircle as TitleIcon} from "@mui/icons-material";
import React from "react";

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});

const value2color = (v = "") => "#" + (v.startsWith("Bass") ? "048e4b" : v.startsWith("Medi") ? "b88c20" : "a80303") + " !important";

export default function ListRemediationsModal(props) {
	return (
		<Dialog
			className={"modalDialog"}
			maxWidth={"md"}
			fullWidth
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-describedby={props.title}
		>
			<DialogTitle elevation={2} component={Paper} className={"modalTitle"}>
				<TitleIcon/>
				{(props.title ?? "Informazioni").toUpperCase()}
				<IconButton
					focusRipple
					onClick={props.handleClose}
					aria-label="close"
				>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent className={"modalBody"}>
				{props.content?.map((row, index) => {
					return (
						<div className={"modalRow" +( index ? " modelRowFurther" : " modalRowFirst" )} key={index}>
							<div className={"modalRowText"}>
								{row?.remediation?.testo || "—"}
							</div>
							<div className={"modalRowControls"}>
								<div className={"control"}>
									<label>Costo:</label>
									<span className={"weighted" + (row?.remediation?.costo ?? "").substring(0,3)}>
										{row?.remediation?.costo || "—"}
									</span>
								</div>
								<div className={"control"}>
									<label>Difficoltà:</label>
									<span className={"weighted" + (row?.remediation?.difficolta ?? "").substring(0,3)}>
										{row?.remediation?.difficolta || "—"}
									</span>
								</div>
							</div>
						</div>
					);
				})}
			</DialogContent>
		</Dialog>
	);
}
