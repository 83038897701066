import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import visuallyHidden from "@mui/utils/visuallyHidden";
import RadarCopertura from "../Components/Gerarchie/RadarCopertura";
import RadarMaturita from "../Components/Gerarchie/RadarMaturita";

function getCounterControlli(controlli) {
  let countNonCoperta = 0;
  let countParzialmenteCoperta = 0;
  let countCoperta = 0;
  let countPiuCoperta = 0;
  let countPiuScoperta = 0;

  controlli.domande.forEach((domanda) => {
    switch (domanda.copertura) {
      case "Non Coperta":
        countNonCoperta++;
        break;
      case "Più coperta che scoperta":
        countPiuCoperta++;
        break;
      case "Più scoperta che coperta":
        countPiuScoperta++;
        break;
      case "Coperta":
        countCoperta++;
        break;
      case "Parzialmente Coperta":
        countParzialmenteCoperta++;
        break;
      default:
        break;
    }
  });

  return {
    countNonCoperta,
    countParzialmenteCoperta,
    countCoperta,
    countPiuCoperta,
    countPiuScoperta,
  };
}

function getTablesData(inputs, storicoAssets, copertura) {
  let arrayResult = [];

  if (inputs.length > 0 && storicoAssets) {
    const dateStorico = storicoAssets[0].toString();

    const selectedInput = inputs.filter((input) => {
      const dateInput = input.dataUltimaModifica.toString();
      return dateInput === dateStorico;
    });
    selectedInput[0].controlli.forEach((controllo) => {
      const {
        countNonCoperta,
        countParzialmenteCoperta,
        countCoperta,
        countPiuCoperta,
        countPiuScoperta,
      } = getCounterControlli(controllo);

      let objResult = {
        name: controllo.controlloRif,
        percentage: controllo.copertura,
        domande: controllo.domande,
        chartsDataCounts: {
          nonCoperta: countNonCoperta,
          parzialmenteCoperta: countParzialmenteCoperta,
          coperta: countCoperta,
          piuCoperta: countPiuCoperta,
          piuScoperta: countPiuScoperta,
        },
      };
      arrayResult.push(objResult);
    });
  }
  return arrayResult;
}

function EnhancedTableHead(props) {
  const headCells = props.headCells;
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          "& th": {
            color: "#033661",
            fontWeight: 600,
            "& .MuiTableSortLabel-root.Mui-active": {
              color: "#003f9d!important",
            },
            "&.MuiTableCell-root": { padding: "8px !important" },
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={"center"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.label}
              {orderBy === headCell.label ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

function ReportTable(props) {
  const inputs = props.inputs;

  const rows = getTablesData(inputs, props.selectedStorico, props.copertura);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead
            rowCount={rows?.length}
            headCells={props.headCells}
          />
          <TableBody
            sx={{ "& td": { color: "#033661", padding: "8px !important" } }}
          >
            {rows?.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ maxWidth: 380 }}>
                    <span style={{ marginLeft: "0" }}>{row.name}</span>
                  </TableCell>

                  <TableCell align="center">
                    <RadarCopertura
                      data={row.domande}
                      report={true}
                    ></RadarCopertura>
                  </TableCell>
                  <TableCell align="center">
                    <RadarMaturita
                      data={row.domande}
                      report={true}
                    ></RadarMaturita>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows?.length}
        rowsPerPage={-1}
        labelRowsPerPage={"Righe per pagina"}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} di ${count !== -1 ? count : `più di ${to}`}`;
        }}
        sx={{ "& .MuiTablePagination-toolbar": { alignItems: "baseline" } }}
      />
    </Box>
  );
}

export default ReportTable;
