import { Box, Button, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, Typography } from "@mui/material";
import React from "react";
import { FormGroup } from "react-bootstrap";
import { connect } from "react-redux"
import { registraUtente } from "../Reducer/AppReducer";
import CustomTextField from "../Ui/CustomTextField";

const initialState = {
    username: '',
    password: '',
    admin: false
}

function Registrazione(props){
    const [state, setState] = React.useState(initialState);

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        setState({ ...state });
        props.registraUtente(state);
    }

    return(
        <>
            <Container maxWidth={false} sx={{ padding: '24px', display: "flex !important", flexDirection: 'row', justifyContent: "center", flexWrap: 'wrap' }}>
                <Grid maxWidth={'lg'} container justifyContent={'center'} spacing={3}>
                    <Grid key={0} item xs={12} md={12}>
                        <Card elevation={6} sx={{ height: '100%' }}>
                            <CardContent>
                                <Grid className='titoloSezioneCenter' >
                                    <Typography
                                        sx={{ display: 'flex', fontWeight: '600', alignItems: 'center', marginBottom: '8px !important', fontSize: '20px', color: '#033661' }}
                                        component={'p'}>
                                        Registrazione Utente
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={'center'}>
                                    <Box component="form" autoComplete="off" onSubmit={e => { handleSubmit(e) }} sx={{
                                            '& .MuiTextField-root': { m: 1, width: { xs: '35ch' }, marginRight: '2rem', marginLeft: '2rem' },
                                            '& .MuiButton-root': { m: 1, color: '#033661', bgcolor: '#f0f0f0', '&:hover': { backgroundColor: '#033661', color: '#fff' }, '&.Mui-disabled': { bgcolor: 'transparent', color: '#00000042 !important' } }
                                        }}>
                                        <div>
                                            <CustomTextField
                                                required
                                                label="Email"
                                                value={state.username}
                                                variant="filled"
                                                onChange={e => setState({ ...state, username: e.target.value })}
                                                onKeyPress={e => { e.code === 'Enter' && handleSubmit() }}
                                            />
                                            <CustomTextField
                                                required
                                                label="Password"
                                                value={state.password}
                                                variant="filled"
                                                type={'password'}
                                                onChange={e => setState({ ...state, password: e.target.value })}
                                            />
                                        </div>
                                        <div style={{ margin: '8px', marginRight: '2rem', marginLeft: '2rem' }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox value={state.admin} onChange={e => setState({ ...state, admin: e.target.checked })} />} label="Admin" />
                                            </FormGroup>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button variant="contained" type="submit" disableFocusRipple disableRipple disableTouchRipple>
                                                Registra
                                            </Button>
                                        </div>
                                    </Box>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
const mapStateToProps = state => {
    return {
        utente: state.authReducer.utente
    }
}

const mapDispatchToProps = dispatch => {
    return {
        registraUtente: data => dispatch(registraUtente(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Registrazione)