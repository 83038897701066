import { Grid, Typography } from "@mui/material";
import * as React from "react";
import Chart from "react-apexcharts";

export default class RadarMaturita extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "",
          data: [
            props.data.filter((a) => a.maturita === "Iniziale").length,
            props.data.filter((a) => a.maturita === "Ripetibile").length,
            props.data.filter((a) => a.maturita === "Definito").length,
            props.data.filter((a) => a.maturita === "Gestito").length,
            props.data.filter((a) => a.maturita === "Ottimizzazione").length,
          ],
        },
      ],
      options: {
        labels: [
          "Iniziale",
          "Ripetibile",
          "Definito",
          "Gestito",
          "Ottimizzato",
        ],
        chart: {
          width: 380,
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
            offsetY: 15,
          },
        },
        colors: ["#335571"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#335571",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return "";
              } else {
                return "";
              }
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
      },
    };
  }

  render() {
    return !this.props.report ? (
      <Grid
        container
        justifyContent={"center"}
        flexWrap={"wrap"}
        item
        xs={12}
        md={6}
      >
        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "8px !important",
              fontSize: "15px",
              color: "#033661",
            }}
            component={"p"}
          >
            Maturità
          </Typography>
        </Grid>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="radar"
          height={320}
          width={600}
        />
      </Grid>
    ) : (
      <Grid
        sx={{ transform: "scale(0.75)", transformOrigin: "center" }}
        justifyContent={"center"}
        flexWrap={"wrap"}
        item
        xs={6}
        md={6}
      >
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="radar"
          height={290}
          width={386}
        />
      </Grid>
    );
  }
}
