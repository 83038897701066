import {
	CardContent,
	Container,
	Grid,
	Typography,
	Card,
	CircularProgress,
	Alert,
	AlertTitle,
	Box,
} from "@mui/material";
import * as React from "react";
import {connect} from "react-redux";
import DashboardTable from "../Ui/DashboardTable";
import {Link} from "react-router-dom";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {setSelectStorico} from "../Reducer/AppReducer";
import "../assets/css/Dashboard.css";
import {getIcon} from "../Service/Shared";

const headCells = [
	{field: "id", label: "ID", numeric: false},
	{field: "titolo", label: "Controllo", numeric: false},
	{field: "copertura", label: "Copertura complessiva", numeric: true},
	{field: "maturita", label: "Maturità complessiva", numeric: true},
	{ field: "domande", label: "Coperture", numeric: false },
	{ field: "domande", label: "Maturità", numeric: false },
];

const coperture = [
	"<25%",
	"tra 25% e 50%",
	">50%",
	"tra 50% e 75%",
	"tra 75% e 100%",
	"<=100%",
];

function Dashboard(props) {
	const [selectedCopertura, setSelectedCopertura] = React.useState("<=100%");
	const [selectedStandard, setSelectedStandard] =
		React.useState("Tutti gli standard");
	const [standards, setStandards] = React.useState([]);

	let inputs = props.dataPlatform?.platform?.inputs
		? [...props.dataPlatform?.platform?.inputs].reverse()
		: [];

	const selectedStorico = (value) => {
		const currentIndex = props.selectStorico.indexOf(value);
		const newSelectList = [];

		if (currentIndex === -1) {
			newSelectList.push(value);
			props.setSelectStorico(newSelectList);
		}
	};

	function selectStandard(inputs, storicoAssets) {
		if (inputs && inputs.length > 0) {
			const dateStorico = storicoAssets[0].toString();

			const selectedInput = inputs.filter((input) => {
				const dateInput = input.dataUltimaModifica.toString();
				return dateInput === dateStorico;
			});
			const filteredByDate = selectedInput[0];
			if (filteredByDate.controlli && filteredByDate.controlli.length > 0) {
				const standards = filteredByDate.controlli.map(
					(controllo) => controllo.titoloStandard
				);
				const allStandard = "Tutti gli standard";
				const orderedStandars = [...new Set(standards)];
				orderedStandars.unshift(allStandard);
				setStandards(orderedStandars);
			}
		}
	}

	React.useEffect(() => {
		if (props.dataPlatform) {
			let index = props.dataPlatform.platform?.inputs?.length - 1;
			selectedStorico(
				props.dataPlatform.platform?.inputs[index]?.dataCaricamento
			);
		}
	}, [props.dataPlatform]);

	React.useEffect(() => {
		if (props.dataPlatform && props.selectStorico) {
			selectStandard(props.dataPlatform?.platform?.inputs, props.selectStorico);
		}
	}, [props.selectStorico, props.dataPlatform]);

	return (
		<div>
			<div className="breadCrumbs">
				{ getIcon("dashboard") }
				<p>
					<Link
						className="link"
						style={{
							display: "flex",
							alignItems: "center",
							textDecoration: "none",
						}}
						to="/"
						onClick={() => {
							props.selected("dashboard");
						}}
					>
						Dashboard
					</Link>
				</p>
			</div>

			<Container
				maxWidth={false}
				className={"DashboardPage"}
				sx={{
					padding: "24px",
					display: "flex !important",
					flexDirection: "row",
					justifyContent: "center",
					flexWrap: "wrap",
				}}
			>
				<Grid maxWidth={"lg"} container justifyContent={"center"} spacing={3}>
					<Grid key={1} item xs={12} md={12}>
						<Card elevation={6} sx={{height: "100%"}}>
							<CardContent>
								<div className={"topControls"}>
									{!props.loading &&
										props.dataPlatform != null &&
										props.dataPlatform?.platform?.inputs?.length > 0 && (
											<FormControl sx={{ flexGrow: 1 }} className={"boxField"}>
												<InputLabel
													id="demo-simple-select-autowidth-label"
													style={{color: "#033361"}}
												>
													STORICO ASSESSMENT
												</InputLabel>
												<Select
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={props.selectStorico}
													onChange={(e) => selectedStorico(e.target.value)}
													label="STORICO ASSESSMENT"
													defaultValue={props.selectStorico}
													sx={{
														padding: "0px!important",
														"& .MuiTypography-root": {
															fontWeight: 600,
															color: "#033361",
														},
														"& .MuiInputBase-input": {
															padding: "8px 16px",
														},
													}}
												>
													{inputs.map((input, index) => {
														return (
															<MenuItem
																key={index}
																value={input.dataCaricamento}
																selected={
																	props.selectStorico.length > 0
																		? props.selectStorico.indexOf(
																		input.dataCaricamento
																	) !== -1
																		: index ===
																		props.dataPlatform.platform.inputs.length - 1
																}
																sx={{
																	"&.MuiListItem-root.Mui-selected": {
																		bgcolor: "#ffffff21",
																		borderRight: "3px solid white",
																	},
																}}
															>
																<ListItemText
																	style={{
																		fontWeight: 600,
																		color: "#033661",
																	}}
																	primary={input.dataCaricamento}
																/>
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										)}
									{!props.loading && standards.length > 2 && (
										<FormControl className={"boxField"}>
											<InputLabel
												id="demo-simple-select-autowidth-label"
											>
												STANDARD
											</InputLabel>
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												value={selectedStandard}
												onChange={(e) => setSelectedStandard(e.target.value)}
												label="COPERTURA"
												defaultValue={selectStandard}
												sx={{
													padding: "0px!important",
													"& .MuiTypography-root": {
														fontWeight: 600,
														color: "#033361",
													},
													"& .MuiInputBase-input": {
														padding: "8px 16px",
													},
												}}
											>
												{standards.map((input, index) => {
													return (
														<MenuItem
															key={index}
															value={input}
															selected={input === selectedCopertura}
															sx={{
																"&.MuiListItem-root.Mui-selected": {
																	bgcolor: "#ffffff21",
																	borderRight: "3px solid white",
																},
															}}
														>
															<ListItemText
																style={{
																	fontWeight: 600,
																	color: "#033661",
																}}
																primary={input}
															/>
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
									{!props.loading && (
										<FormControl className={"boxField"}>
											<InputLabel
												id="demo-simple-select-autowidth-label"
											>
												COPERTURA
											</InputLabel>
											<Select
												labelId="demo-simple-select-autowidth-label"
												id="demo-simple-select-autowidth"
												value={selectedCopertura}
												onChange={(e) => setSelectedCopertura(e.target.value)}
												label="COPERTURA"
												defaultValue={selectedCopertura}
												sx={{
													padding: "0px!important",
													"& .MuiTypography-root": {
														fontWeight: 600,
														color: "#033361",
													},
													"& .MuiInputBase-input": {
														padding: "8px 16px",
													},
												}}
											>
												{coperture.map((input, index) => {
													return (
														<MenuItem
															key={index}
															value={input}
															selected={input === selectedCopertura}
															sx={{
																"&.MuiListItem-root.Mui-selected": {
																	bgcolor: "#ffffff21",
																	borderRight: "3px solid white",
																},
															}}
														>
															<ListItemText
																style={{
																	fontWeight: 600,
																	color: "#033661",
																}}
																primary={input}
															/>
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
								</div>
								<Grid className="titoloSezioneCenter">
									<Typography
										sx={{
											display: "flex",
											fontWeight: "600",
											alignItems: "center",
											marginBottom: "8px !important",
											fontSize: "20px",
											color: "#033661",
										}}
										component={"p"}
									>
										Overview{" "}
									</Typography>
								</Grid>
								<Grid container justifyContent={"center"}>
									{props.loading ? (
										<CircularProgress/>
									) : props.dataPlatform != null &&
									props.dataPlatform?.platform?.inputs?.length > 0 &&
									props.dataPlatform?.platform?.inputs ? (
										<>
											<DashboardTable
												selectedStandard={selectedStandard}
												copertura={selectedCopertura}
												selectedStorico={props.selectedStorico}
												inputs={props.dataPlatform?.platform?.inputs}
												topFiveRemediation={props.topFiveRemediation}
												headCells={headCells}
												selectControllo={props.selected}
											/>
										</>
									) : (
										<Grid>
											<Box sx={{padding: "16px"}}>
												<Alert severity="warning">
													<AlertTitle>Avviso</AlertTitle>
													Caricare almeno un documento valido
												</Alert>
											</Box>
										</Grid>
									)}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		loading: state.appReducer.loading,
		upload: state.appReducer.upload,
		dataPlatform: state.appReducer.dataPlatform,
		utente: state.authReducer.utente,
		selectStorico: state.appReducer.selectStorico,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setSelectStorico: (flag) => dispatch(setSelectStorico(flag)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
