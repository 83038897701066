import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {Grid, IconButton, Typography, Paper} from "@mui/material";
import {Close as CloseIcon, BuildCircle as TitleIcon} from "@mui/icons-material";

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function RemediationDetailsModal(props) {
	return (
		<Dialog
			className={"modalDialog"}
			maxWidth="sm"
			fullWidth
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-describedby={props.title ?? ""}
		>
			<DialogTitle elevation={2} component={Paper} className={"modalTitle"}>
				<TitleIcon/>
				{props.title?.toUpperCase()}
				<IconButton
					focusRipple
					sx={{position: "absolute", right: 8, top: 8, color: "#033661"}}
					onClick={props.handleClose}
					aria-label="close"
				>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent className={"modalBody"}>
				<div className={"modalRow"}>
					<div className={"modalRowText"}>
						{props.content?.remediation?.testo || "—"}
					</div>
					<div className={"modalRowControls"}>
						<div className={"control"}>
							<label>Costo:</label>
							<span className={"weighted" + (props.content?.remediation?.costo ?? "").substring(0,3)}>
								{props.content?.remediation?.costo || "—"}
							</span>
						</div>
						<div className={"control"}>
							<label>Difficoltà:</label>
							<span className={"weighted" + (props.content?.remediation?.difficolta ?? "").substring(0,3)}>
								{props.content?.remediation?.difficolta || "—"}
							</span>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
