/* eslint-disable react-hooks/exhaustive-deps */
import {
	Close as IconClose,
	ContentCopy as IconCompare,
	Dashboard as IconDashboard,
	ArrowOutward as IconGrow,
	MenuBook as IconNormative,
	CropFree as IconUnknown,
	NavigateNext as IconPath,
	BuildCircle as IconRemediation,
	Assessment as IconReport,
	ArrowRightAlt as IconStable,
	FileUpload as IconUpload
} from "@mui/icons-material";

export const getIcon = (c = "", props = {} ) => {
	switch ( c.toLowerCase().substring(0,4) ) {
		case "clos" : return <IconClose {...props} />;
		case "comp" : return <IconCompare {...props} />;
		case "dash": return <IconDashboard {...props} />;
		case "down" :
			props.className ??= "weighted-danger";
			return <IconGrow sx={{transform:"scaleY(-1)"}} {...props} />;
		case "grow" :
			props.className ??= "weighted-high";
			return <IconGrow {...props} />;
		case "norm" : return <IconNormative {...props} />;
		case "path" : return <IconPath {...props} />;
		case "reme" : return <IconRemediation {...props} />;
		case "repo" : return <IconReport {...props} />;
		case "stab" :
			props.className ??= "weighted-medium";
			return <IconStable {...props} />;
		case "uplo" : return <IconUpload {...props} />;
		default : return <IconUnknown {...props} />;
	}
};

const internalWeightFromPercentage = x =>{
	if ( isNaN(x) ) return "unk";
	if ( x <= 15 ) return "danger";
	if ( x <= 35 ) return "low";
	if ( x <= 60 ) return "medium";
	if ( x <= 85 ) return "high";
	return "max";
}
export const weightFromPercentage = p => ("weighted-"+internalWeightFromPercentage( parseFloat(p) ));

const coperture = {
	"Non Coperta": "danger",
	"Più scoperta che coperta": "low",
	"Parzialmente coperta": "medium",
	"Più coperta che scoperta": "high",
	"Coperta": "max"
};

export const weightFromCopertura = (c) => ("weighted-" + (coperture[c] ?? 'unk'));

const maturita = {
	"Iniziale": "danger",
	"Ripetibile": "low",
	"Definito": "medium",
	"Gestito": "high",
	"Ottimizzazione": "max",
	"Ottimizzato": "max"
};

export const weightFromMaturita = (m="-") => ("weighted-" + (maturita[m] ?? 'unk'));

export const cleanPercentage = ( p, decs = 2 ) => {
	switch (typeof p) {
		case "string" :
			if ( p === "" ) return "—";
			p = parseFloat(p);
		case "number" :
			p = p.toFixed(decs);
			break;
		default:
			return "—";
	}
	p = p.match(/[.,]/) ? p.replace(/0*$/,'').replace(/[.,]$/,'') : p;
	return p === "0" ? "—" : p+"%";
};

const valueKeys = {
	copertura: {
		nonCoperta: "Non coperta",
		piuScoperta: "Più scoperta che coperta",
		parzialmenteCoperta: "Parzialmente coperta",
		piuCoperta: "Più coperta che scoperta",
		coperta: "Coperta"
	},
	maturita: {
		iniziale: "Iniziale",
		ripetibile: "Ripetibile",
		definito: "Definito",
		gestito: "Gestito",
		ottimizzato: "Ottimizzato"
	}
};

export const filter4key = s => String(s||"-").toLowerCase().replace(/[^a-z]+/g,"").substring(0,6);

const reverseKeys = {};

if ( ! reverseKeys.copertura ) {
	reverseKeys.copertura = {};
	reverseKeys.maturita = {};
	Object.entries( valueKeys.copertura).forEach( ([k,v])=>{
		reverseKeys.copertura[filter4key(v)] = k;
	});
	Object.entries( valueKeys.maturita).forEach( ([k,v])=>{
		reverseKeys.maturita[filter4key(v)] = k;
	});
};

export const getValueKeys = () => (JSON.parse(JSON.stringify(valueKeys)));

export const getReverseKeys = () => (JSON.parse(JSON.stringify(reverseKeys)));

export const colorsSequence = () => ["#009000", "#82cb2a", "#febf00", "#ff7300", "#a80303"];

export const countDomandeCopertura = (domande = []) => {
	const cop = getValueKeys().copertura;
	const rev = getReverseKeys().copertura;
	const cnt = {};
	Object.keys( cop ).forEach( k => { cnt[k] = 0 });
	domande.forEach( d => {
		if ( ! d?.copertura ) return;
		const kc = rev[filter4key(d.copertura )];
		if ( cop[kc] ) cnt[kc]++;
	});
	return cnt;
};
export const countDomandeMaturita = (domande = []) => {
	const mat = getValueKeys().maturita;
	const rev = getReverseKeys().maturita;
	const cnt = {};
	Object.keys( mat ).forEach( k => { cnt[k] = 0 });
	domande.forEach( d => {
		if ( ! d?.maturita ) return;
		const kc = rev[filter4key(d.maturita )];
		if ( mat[kc] ) cnt[kc]++;
	});
	return cnt;
};

export const getMinMaturita = ( domande = [] ) =>{
	if ( domande?.domande ) return getMinMaturita(domande.domande );
	const labs = getValueKeys().maturita;
	const revs = getReverseKeys().maturita;
	if (Array.isArray(domande) && domande.length) {
		const mats = domande.map( d => filter4key(d?.maturita));
		if (mats.some(d => (d === "inizia"))) return labs[revs.inizia];
		if (mats.some(d => (d === "ripeti"))) return labs[revs.ripeti];
		if (mats.some(d => (d === "defini"))) return labs[revs.defini];
		if (mats.some(d => (d === "gestit"))) return labs[revs.gestit];
		if (mats.some(d => (d === "ottimi"))) return labs[revs.ottimi];
	}
	return labs[revs.inizia];
}