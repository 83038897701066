import React from "react";
import "./MySvg.css";
import {arcDataFromPercent, cleanPercentage, parseCircularProps} from "./MySvgShared";

const parseValues = ( vals = [] ) => {
	vals = vals.filter( v => parseFloat(v?.value ?? 0) );
	let tot = 0;
	vals.forEach( v => {
		v.value = parseFloat(v.value);
		tot += v.value;
	});
	vals.forEach( v => { v.percent = (100 * v.value) / tot; });
	return vals;
};

const SvgPie = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = parseCircularProps(props, {  values: parseValues(props.values ) });
		let lastAngle = this.state.startAngle;
		this.state.values.forEach( v => {
			const sd = arcDataFromPercent(
				this.state.radius,
				this.state.radius,
				this.state.radius - this.state.border - this.state.borderOffset,
				v.percent,
				lastAngle
			);
			lastAngle = sd.angle;
			if ( props.debug ) {
				console.log(v,sd,lastAngle);
			}
			v.d = sd.d;
		});
		const classes = ["SvgPie"];
		if ( this.state.sizeClass ) classes.push("Size"+this.state.sizeClass);
		if ( this.state.maskid ) classes.push("Masked");
		if ( this.props.className ) classes.push(this.props.className);
		this.state.className = classes.join(' ');
	}
	render() {
		return (
			<div
				className={this.state.className}
				data-value={this.state.label || this.state.value}
				title={this.props.title || this.state.label || this.props.value || ""}
				style={{
					width: String(this.state.size)+"px",
					height: String(this.state.size)+"px"
				}}
			>
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
					{ (!! this.state.maskid ) && this.state.mask }
					<circle
						className={"SvgPieExternal"}
						r={ this.state.radius - this.state.border / 2}
						cx={this.state.radius}
						cy={this.state.radius}
						stroke={this.state.borderColor}
						strokeWidth={this.state.border}
						fill={this.state.bgColor}
						mask={ this.state.maskid ? `url(#${this.state.maskid})` : undefined }
					/>
					{ this.state.values.length > 0 && this.state.values.map( (v,idx) => {
						const t = this.props.labels ? ((v.label ? `${v.label}, ` : "")+
							cleanPercentage(v.percent)+
							(parseFloat(v.value) !== v.percent ? ` (${v.value})` : "")) : false;
						return (
							<path
								key={idx}
								className={"SvgPieInternal"+(v.className ? " "+v.className : "")}
								fill={v.color||undefined}
								d={ v.d }
								mask={ this.state.maskid ? `url(#${this.state.maskid})` : undefined }
							>
								{ t && <title>{t}</title>}
								{ t && <desc>{t}</desc>}
							</path>
						);
					})}
				</svg>
			</div>
		)
	}
}

export default SvgPie;

/* © 2023 balestra@altersoftware.it */
