import { refreshToken, setLogout } from "../Reducer/AuthReducer/authAction";
import { setErrorDetail } from "../Reducer/AppReducer/appAction";

import instance from "./Api";

const setup = (store) => {
  const { dispatch } = store;
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      dispatch(refreshToken(localStorage.getItem("accessToken", false)));
      return response;
    },
    async (error) => {
      if (error.response.status === 403) {
        dispatch(setErrorDetail(error.response.data));
      }
      const originalConfig = error.config;
      if (originalConfig.url !== "/security/login" && error.config) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await instance.post("/refreshtoken", {
              refreshToken: localStorage.getItem("refreshToken"),
            });
            const { accessToken } = rs.data;
            dispatch(refreshToken(accessToken, true));
            localStorage.setItem("accessToken", accessToken);
            return instance(originalConfig);
          } catch (error) {
            return dispatch(setLogout());
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export default setup;
