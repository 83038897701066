import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {IconButton, Tooltip} from "@mui/material";
import Modal from "../../Ui/Modal";
import { ContentPaste as NoteIcon, BuildCircle as RemediationIcon } from "@mui/icons-material";
import RemediationDetailsModal from "../../Ui/RemediationDetailsModal";
import {
	weightFromCopertura, weightFromMaturita,
} from "../../Service/Shared";
import SvgBulbProgress from "../../Custom/MySVG/SvgBulbProgress";

export default function TabellaTitoloControllo(props) {
	const [state, setState] = React.useState({
		open: false,
		note: "",
		openDetailRemediation: false,
		row: "",
	});
	const handleOpen = (note) => {
		setState({...state, open: true, note: note});
	};
	const handleClose = () => {
		setState({...state, open: false});
	};

	const handleOpenRemediationDetail = (row) => {
		setState({...state, openDetailRemediation: true, row: row});
	};

	const handleCloseRemediationDetail = () => {
		setState({...state, openDetailRemediation: false});
	};

	return (
		<React.Fragment>
			<TableContainer elevation={0} component={Paper}>
				<Table sx={{minWidth: 650, borderCollapse: "unset"}}>
					<TableHead sx={{"& tr > th": {color: "#033661"}}}>
						<TableRow>
							<TableCell align="center">DOMANDA</TableCell>
							<TableCell align="center">COPERTURA</TableCell>
							<TableCell align="center">MATURITÀ</TableCell>
							<TableCell align="center">ASSET</TableCell>
							<TableCell align="center">NOTA</TableCell>
							<TableCell align="center">REMEDIATION</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.domande &&
							props.domande.map((row) => (
								<TableRow
									key={row.idDomanda}
									sx={{
										width:"5%",
										"&:last-child td, &:last-child th": {border: 0},
										"& td": {color: "#033661"},
									}}
								>
									<TableCell align="left" sx={{width:"60%"}}>{row.testo}</TableCell>
									<TableCell align="center" sx={{width:"5%"}}>
										<SvgBulbProgress width={140} copertura={row.copertura} />
									</TableCell>
									<TableCell align="center" sx={{width:"5%"}}>
										<SvgBulbProgress width={140} maturita={row.maturita} />
									</TableCell>
									<TableCell align="center" sx={{width:"20%"}}>{row.assets[0]}</TableCell>
									<TableCell align="center" sx={{width:"5%"}}>
										{row.nota && row.nota.length > 0 ? (
											<Tooltip title="Visualizza Nota">
												<IconButton
													onClick={() => {
														handleOpen(row.nota);
													}}
												>
													<NoteIcon sx={{color: "#033661"}}/>
												</IconButton>
											</Tooltip>
										) : (
											<Tooltip title="Nota non disponibile">
												<IconButton>
													<NoteIcon
														sx={{
															color: "grey",
															opacity: "0.5",
															pointerEvents: "none",
														}}
													></NoteIcon>
												</IconButton>
											</Tooltip>
										)}
									</TableCell>
									<TableCell align="center" sx={{width:"5%"}}>
										{row.copertura !== "Coperta" ? (
											<Tooltip title="Apri Dettaglio Remediation">
												<IconButton
													onClick={() => {
														handleOpenRemediationDetail(row);
													}}
												>
													<RemediationIcon sx={{color: "#033661"}}/>
												</IconButton>
											</Tooltip>
										) : (
											<Tooltip title="Dettaglio Remediation non disponibile">
												<IconButton
													onClick={() => {
														handleOpenRemediationDetail(row);
													}}
												>
													<RemediationIcon
														sx={{
															color: "grey",
															opacity: "0.5",
															pointerEvents: "none",
														}}
													/>
												</IconButton>
											</Tooltip>
										)}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>

			<Modal open={state.open} content={state.note} handleClose={handleClose}/>
			<RemediationDetailsModal
				open={state.openDetailRemediation}
				content={state.row}
				handleClose={handleCloseRemediationDetail}
				title="Dettaglio Remediation"
			/>
		</React.Fragment>
	);
}
