import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import visuallyHidden from "@mui/utils/visuallyHidden";
import CircularStatic from "../Components/Common/CircularProgress";
import {weightFromMaturita} from "../Service/Shared";

function getCounterControlli(controlli) {
  let countNonCoperta = 0;
  let countParzialmenteCoperta = 0;
  let countCoperta = 0;
  let countPiuCoperta = 0;
  let countPiuScoperta = 0;

  controlli.domande.forEach((domanda) => {
    switch (domanda.copertura) {
      case "Non Coperta":
        countNonCoperta++;
        break;
      case "Più coperta che scoperta":
        countPiuCoperta++;
        break;
      case "Più scoperta che coperta":
        countPiuScoperta++;
        break;
      case "Coperta":
        countCoperta++;
        break;
      case "Parzialmente Coperta":
        countParzialmenteCoperta++;
        break;
      default:
        break;
    }
  });

  return {
    countNonCoperta,
    countParzialmenteCoperta,
    countCoperta,
    countPiuCoperta,
    countPiuScoperta,
  };
}

function getTablesData(inputs, storicoAssets) {
  let arrayResult = [];

  if (inputs.length > 0 && storicoAssets) {
    const dateStorico = storicoAssets[0].toString();

    const selectedInput = inputs.filter((input) => {
      const dateInput = input.dataUltimaModifica.toString();
      return dateInput === dateStorico;
    });
    selectedInput[0].controlli.forEach((controllo) => {
      const {
        countNonCoperta,
        countParzialmenteCoperta,
        countCoperta,
        countPiuCoperta,
        countPiuScoperta,
      } = getCounterControlli(controllo);

      let objResult = {
        name: controllo.controlloRif,
        percentage: controllo.copertura,
        domande: controllo.domande,
        maturita: controllo.maturita,
        chartsDataCounts: {
          nonCoperta: countNonCoperta,
          parzialmenteCoperta: countParzialmenteCoperta,
          coperta: countCoperta,
          piuCoperta: countPiuCoperta,
          piuScoperta: countPiuScoperta,
        },
      };
      arrayResult.push(objResult);
    });
  }
  return arrayResult;
}

function EnhancedTableHead(props) {
  const headCells = props.headCells;
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          "& th": {
            color: "#033661",
            fontWeight: 600,
            "& .MuiTableSortLabel-root.Mui-active": {
              color: "#003f9d!important",
            },
            "&.MuiTableCell-root": { padding: "8px !important" },
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={"center"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

function ReportTablePlus(props) {
  const inputs = props.inputs;

  const rows = getTablesData(inputs, props.selectedStorico, props.copertura);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead
            rowCount={rows?.length}
            headCells={props.headCells}
          />
          <TableBody
            sx={{ "& td": { color: "#033661", padding: "8px !important" } }}
          >
            {rows?.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ maxWidth: 380 }}>
                    <span style={{ marginLeft: "0" }}>{row.name}</span>
                  </TableCell>

                  <TableCell>
                    <span style={{ marginLeft: "0" }}>
                      <div
                        style={{
                          position: "relative",
                          marginLeft: "34%",
                          transform: "scale(1.4)",
                        }}
                      >
                        {CircularStatic(row.percentage, "black")}
                      </div>
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span
                      className={weightFromMaturita(row.maturita)}
                      style={{ marginLeft: "60px" }}
                    >
                      <b>{row.maturita}</b>
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows?.length}
        rowsPerPage={-1}
        labelRowsPerPage={""}
        labelDisplayedRows={({ from, to, count }) => {
          "";
        }}
        sx={{ "& .MuiTablePagination-toolbar": { alignItems: "baseline" } }}
      />
    </Box>
  );
}

export default ReportTablePlus;
