import React from "react";
import { Chart } from "react-google-charts";
import "../../assets/css/PieDomande.css";
import {colorsSequence, filter4key as filterLabel, getReverseKeys, getValueKeys} from "../../Service/Shared";

const valueKeys = getValueKeys();
const reverseKeys = getReverseKeys();

const parseData = data => {
	if (! Array.isArray(data) ) return undefined;
	const out = { copertura : {}, maturita : {} };
	Object.keys( valueKeys.maturita ).forEach( k => { out.maturita[k] = 0 });
	Object.keys( valueKeys.copertura ).forEach( k => { out.copertura[k] = 0 });
	data.forEach( c => {
		if ( ! Array.isArray(c.domande) ) return;
		c.domande.forEach( d => {
			if ( ! d ) return;
			const kc = reverseKeys.copertura[filterLabel(d?.copertura )];
			if ( valueKeys.copertura[kc] ) out.copertura[kc]++;
			const km = reverseKeys.maturita[filterLabel(d?.maturita)];
			if ( valueKeys.maturita[km] ) out.maturita[km]++;
		});
	});
	return out;
};
const PieDomandeGruppoControlli = class extends React.Component {
	constructor(prop={}) {
		super(prop);
		const data = parseData(prop?.data?.data);
		this.state = data ? {
			chartSize : "m",
			copertura : {
				data : [
					["Domande", "Copertura"],
					[valueKeys.copertura.coperta, data.copertura.coperta || 0 ],
					[valueKeys.copertura.piuCoperta, data.copertura.piuCoperta || 0],
					[valueKeys.copertura.parzialmenteCoperta, data.copertura.parzialmenteCoperta || 0],
					[valueKeys.copertura.piuScoperta, data.copertura.piuScoperta || 0],
					[valueKeys.copertura.nonCoperta, data.copertura.nonCoperta || 0]
				],
				options: {
					pieHole: 0.33,
					fontName: "Titillium Web",
					fontSize:"12px",
					pieSliceBorderColor: 'none',
					backgroundColor : "transparent",
					colors: colorsSequence()
				}
			},
			maturita : {
				data : [
					["Domande", "Maturità"],
					[valueKeys.maturita.ottimizzato, data.maturita.ottimizzato || 0 ],
					[valueKeys.maturita.gestito, data.maturita.gestito || 0 ],
					[valueKeys.maturita.definito, data.maturita.definito || 0 ],
					[valueKeys.maturita.ripetibile, data.maturita.ripetibile || 0 ],
					[valueKeys.maturita.iniziale, data.maturita.iniziale || 0 ]
				],
				options: {
					is3D: true,
					fontName: "Titillium Web",
					fontSize:"12px",
					colors: colorsSequence()
				}
			}
		} : {};
		if ( prop.size && ['m','l','xl'].includes(prop.size.toLowerCase()) ) this.state.chartSize = prop.size.toLowerCase();
	}

	render() {
		if (! this.state.copertura ) return "";
		return(
			<div className={"PieDomande PieDomande-"+this.state.chartSize+(this.props.className?" "+this.props.className:"")}>
				<div>
					<Chart
						chartType="PieChart"
						data={this.state.copertura.data}
						options={this.state.copertura.options}
						width={"100%"}
						height={"100%"}
					/>
					<title>Copertura</title>
				</div>
				<div>
					<Chart
						chartType="PieChart"
						data={this.state.maturita.data}
						options={this.state.maturita.options}
						width={"100%"}
						height={"100%"}
					/>
					<title>Maturità</title>
				</div>
			</div>
		);
	}
};

export default PieDomandeGruppoControlli;