import {
	Card,
	CardContent,
	Container,
	Grid,
	Tooltip,
	Typography
} from "@mui/material";
import * as React from "react";
import {Link} from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import TabellaTitoloControllo from "./Gerarchie/TabellaTitoloControllo";
import TabellaControlli from "./Gerarchie/TabellaControlli";
import RadarMaturita from "./Gerarchie/RadarMaturita";
import RadarCopertura from "./Gerarchie/RadarCopertura";
import {cleanPercentage, getIcon, weightFromPercentage} from "../Service/Shared";

class Gerarchie extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			changeTable: false,
			domande: [],
			controlloRif: "",
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.gerarchia !== this.props.gerarchia) {
			this.setState({changeTable: false});
		}
	}

	getListaControlli = () => {
		let splitGer = this.props.gerarchia.split(" ");
		var data = this.props.dataPlatform.controlli.filter((a) =>
			a.controlloRif.includes(splitGer[0])
		);
		return data;
	};

	getCompletamento = () => {
		let data = this.getListaControlli();
		let percentage = 0;
		data.forEach((e) => {
			percentage = Number(e.copertura.replace("%", "")) + percentage;
		});
		return percentage / data.length;
	};

	handleClickTable = (e, domande, controlloRif) => {
		this.setState({
			changeTable: e,
			domande: domande,
			controlloRif: controlloRif,
		});
	};

	render() {
		let data = this.getListaControlli();
		const pathSep = getIcon("path");
		return (
			<div>
				<div className="breadCrumbs">
					{ getIcon("Normativa") }
					<p>{data[0]?.titoloStandard}</p>
					{ pathSep }
					<p>{this.props.controllo}</p>
					{ pathSep }
					<p>{this.props.gerarchia}</p>
					{this.state.changeTable ? (
						<>
							{ pathSep }
							<p>{ this.state.controlloRif }</p>
						</>
					) :  "" }
				</div>
			<Container
				maxWidth={false}
				sx={{
					padding: "24px",
					display: "flex !important",
					flexDirection: "row",
					justifyContent: "center",
					flexWrap: "wrap",
				}}
			>
				<Grid container maxWidth={"lg"} justifyContent="center" spacing={3}>
					<Grid item xs={12}>
						<Card elevation={6} sx={{height: "100%"}}>
							<CardContent>
								<Grid container>
									<Container sx={{
										marginBottom: "2rem",
										display: "flex !important",
										flexDirection: "row",
										alignItems: "center",
										flexWrap: "wrap",
									}}>
										<div
											style={{
												padding: "0 1rem 0 0",
												fontWeight: "600",
												fontSize: "20px",
												color: "#033661",
												maxWidth : "50%"
											}}
										>
											{this.props.gerarchia.replace(/^[^ ]+ /, '').replace(/;$/, '')}:
										</div>
										<div style={{flexGrow: 1}}>
											<Tooltip title={cleanPercentage(this.getCompletamento())}>
												<ProgressBar
													className={weightFromPercentage(this.getCompletamento())}
													now={this.getCompletamento()}
													visuallyHidden
												/>
											</Tooltip>
										</div>
									</Container>
									<Grid item xs className="titoloSezione">
										<Typography
											sx={{
												display: "flex",
												"&:after": {marginTop: "5px !important"},
												alignItems: "center",
												fontSize: "20px",
												color: "#033661",
											}}
											component={"p"}
										>
											<Typography
												component={"span"}
												onClick={() => {
													this.state.changeTable &&
													this.handleClickTable(false);
												}}
												sx={{
													fontSize: "20px",
													...(this.state.changeTable && {
														color: "#003f9d!important",
														cursor: "pointer",
														"&:hover": {
															textDecoration: "underline",
															color: "#033661!important",
														},
													}),
												}}
											>
												{this.props.gerarchia.replace(/ .*/, '') }
											</Typography>
											{this.state.changeTable && (
												<React.Fragment>
													{ pathSep }
													<b>{ this.state.controlloRif.replace(/ .*/,'') }</b>
												</React.Fragment>
											)}
										</Typography>
									</Grid>
								</Grid>
								{!this.state.changeTable ? (
									<TabellaControlli
										data={this.getListaControlli()}
										handleClickTable={this.handleClickTable}
									/>
								) : (
									<>
										<p style={{color: "#033661"}}>
											<b>{ this.state.controlloRif.replace(/^[^ ]+ /,'') }</b>
										</p>
										<TabellaTitoloControllo domande={this.state.domande}/>
									</>
								)}
							</CardContent>
						</Card>
					</Grid>
					{this.state.changeTable && (
						<Grid item xs={12}>
							<Card elevation={6} sx={{height: "100%"}}>
								<CardContent>
									<Grid container spacing={3}>
										<React.Fragment>
											<Grid
												item
												xs={12}
												md={12}
												sx={{
													justifyContent: "center",
												}}
												className="titoloSezione"
											>
												<Typography
													sx={{
														display: "flex",
														"&:after": {marginTop: "0 !important"},
														fontWeight: "600",
														alignItems: "center",
														marginBottom: "8px !important",
														fontSize: "15px",
														color: "#033661",
													}}
													component={"p"}
												>
													{this.state.controlloRif}
												</Typography>
											</Grid>
											<RadarCopertura data={this.state.domande}/>
											<RadarMaturita data={this.state.domande}/>
										</React.Fragment>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					)}
				</Grid>
			</Container>
			</div>
		);
	}
}

export default Gerarchie;
