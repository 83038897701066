import {styled, TextField} from "@mui/material";
const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#033661!important',
    },

    '& .MuiFilledInput-root': {
        color: '#033661',
        backgroundColor: '#0000000f',
        '&:hover': {
            backgroundColor: '#0000000f',
        },
        '&:before': {
            borderBottom: '3px solid white !important',
        },
        '&:hover:before': {
            borderBottom: '3px solid white !important',
        },
        '&:after': {
            borderBottom: '3px solid #16608a',
        },
        '&.Mui-focused': {
            backgroundColor: '#0000000f!important',
        },
    },
    '& label.MuiInputLabel-root': {
        color: '#033661',
    }
});

export default CustomTextField