import React from "react";
import SvgPie from "../../Custom/MySVG/SvgPie";

const PieCoperturaSvgSmall = class extends React.Component {
	constructor(prop) {
		super(prop);
		const data = prop?.data || {};
		this.state = {
			values : [
				{label: "Coperte", value: data.coperta,className:"weighted-max"},
				{label:"Più coperte che scoperte", value: data.piuCoperta, className: "weighted-high"},
				{label:"Parzialmente coperte", value: data.parzialmenteCoperta, className: "weighted-medium"},
				{label: "Più scoperte che coperte", value: data.piuScoperta,className: "weighted-low"},
				{label:"Non coperte", value: data.nonCoperta, className: "weighted-danger"}
			]
		};
		if ( prop.debug || data.debug ) this.state.debug = true;
	}

	render() {
		return(
			<SvgPie
				debug={this.state.debug}
				border={0}
				hole={this.props.hole||.5}
				size={this.props.size}
				values={this.state.values}
				labels={true}
			/>
		);
	}
};

export default PieCoperturaSvgSmall;