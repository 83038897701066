// @ts-ignore
import React from "react";
// @ts-ignore
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Config/ConfigureStore";
import setup from "./Service/SetupInterceptors";

window.document.getElementsByTagName('body')[0].setAttribute("theme", window.location.hostname.includes('-dev.')?'HB':'DP');
setTimeout( ()=>{
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById("siteRootNode"),
        setup(store)
    );
}, 2500);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
