import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {cleanPercentage, getIcon, weightFromPercentage} from "../Service/Shared";
import SvgPieProgress from "../Custom/MySVG/SvgPieProgress";

// function filterRowsByCopertura(copertura, rows) {
// 	//Ordino per copertura
// 	if (copertura) {
// 		switch (copertura) {
// 			case "100%":
// 				rows = rows.filter((item) => {
// 					return percentageToNumber(item.percentage) === 100;
// 				});
// 				break;
// 			case "<=100%":
// 				rows = rows.filter((item) => {
// 					return percentageToNumber(item.percentage) <= 100;
// 				});
// 				break;
// 			case ">75%":
// 				rows = rows.filter((item) => {
// 					return percentageToNumber(item.percentage) > 75;
// 				});
// 				break;
// 			case "tra 50% e 75%":
// 				rows = rows.filter((item) => {
// 					return (
// 						percentageToNumber(item.percentage) > 50 &&
// 						percentageToNumber(item.percentage) < 75
// 					);
// 				});
// 				break;
// 			case ">50%":
// 				rows = rows.filter((item) => {
// 					return percentageToNumber(item.percentage) > 50;
// 				});
// 				break;
// 			case "tra 25% e 50%":
// 				rows = rows.filter((item) => {
// 					return (
// 						percentageToNumber(item.percentage) > 25 &&
// 						percentageToNumber(item.percentage) < 50
// 					);
// 				});
// 				break;
// 			case "<25%":
// 				rows = rows.filter((item) => {
// 					return percentageToNumber(item.percentage) < 25;
// 				});
// 				break;
// 			case "0%":
// 				rows = rows.filter((item) => {
// 					return percentageToNumber(item.percentage) === 0;
// 				});
// 				break;
// 			default:
// 				break;
// 		}
// 	}
// 	return rows;
// }
//
// function filterRowsByStandard(standard, rows) {
// 	//Ordino per copertura
// 	if (standard.length > 0) {
// 		if (standard === "Tutti gli standard") {
// 			return rows;
// 		} else {
// 			rows = rows.filter((item) => {
// 				return item.standard === standard;
// 			});
// 		}
// 	}
// 	return rows;
// }
//
// function percentageToNumber(percentageString) {
// 	percentageString.substring(0, percentageString.length - 1); // Masteringjs.io
// 	percentageString.replace(/.$/, "");
// 	return parseFloat(percentageString);
// }
//
// function getCounterControlli(controlli) {
// 	let countNonCoperta = 0;
// 	let countParzialmenteCoperta = 0;
// 	let countCoperta = 0;
// 	let countPiuCoperta = 0;
// 	let countPiuScoperta = 0;
//
// 	controlli.domande.forEach((domanda) => {
// 		switch (domanda.copertura) {
// 			case "Non Coperta":
// 				countNonCoperta++;
// 				break;
// 			case "Più coperta che scoperta":
// 				countPiuCoperta++;
// 				break;
// 			case "Più scoperta che coperta":
// 				countPiuScoperta++;
// 				break;
// 			case "Coperta":
// 				countCoperta++;
// 				break;
// 			case "Parzialmente coperta":
// 				countParzialmenteCoperta++;
// 				break;
// 			default:
// 				break;
// 		}
// 	});
//
// 	return {
// 		countNonCoperta,
// 		countParzialmenteCoperta,
// 		countCoperta,
// 		countPiuCoperta,
// 		countPiuScoperta,
// 	};
// }

const getTablesData = (
	inputs,
	storicoAssets,
	copertura,
	selectedStandard,
	dataConfronto
) => {
	let arrayResult = [];

	const dateStorico = storicoAssets[0].toString();

	const selectedInput = inputs.filter((input) => {
		const dateInput = input.dataUltimaModifica.toString();
		return dateInput === dateStorico;
	});
	selectedInput[0].controlli.forEach((controllo) => {
		let objResult = {
			id: controllo.idControllo,
			name: controllo.controlloRif,
			standard: controllo.titoloStandard,
			percentage: controllo.copertura,
		};
		arrayResult.push(objResult);
	});
	if (dataConfronto.length > 0) {
		let arrayResult2 = [];
		const selectedInput2 = inputs.filter((input) => {
			const dateInput = input.dataUltimaModifica.toString();
			return dateInput === dataConfronto;
		});

		if (selectedInput2.length > 0) {
			selectedInput2[0].controlli.forEach((controllo) => {
				let objResult = {
					id: controllo.idControllo,
					name: controllo.controlloRif,
					standard: controllo.titoloStandard,
					percentageDifferent: controllo.copertura,
				};
				arrayResult2.push(objResult);
			});
			const arrayMultiple = arrayResult.map((t1) => ({
				...t1,
				...arrayResult2.find((t2) => t2.id === t1.id),
			}));
			return arrayMultiple;
		}
	}

	return arrayResult;
}

function EnhancedTableHead(props) {
	const headCells = props.headCells;
	const {order, orderBy, onRequestSort} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow
				sx={{
					"& th": {
						color: "#033661",
						fontWeight: 600,
						"& .MuiTableSortLabel-root.Mui-active": {
							color: "#003f9d!important",
						},
						"&.MuiTableCell-root": {padding: "8px !important"},
					},
				}}
			>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.field}
						align={"center"}
						sx={{whiteSpace: "nowrap"}}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.field ? order : false}
					>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const iconSize = "48px";
const growIcon = getIcon("grow", { style: { width: iconSize, height: iconSize }});
const stableIcon = getIcon("stable", { style: { width: iconSize, height: iconSize }});
const downIcon = getIcon("down", { style: { width: iconSize, height: iconSize }});

function ConfrontoTable(props) {
	const inputs = props.inputs;
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("costo");
	const [page, setPage] = React.useState(0);
	const rowsPerPageOptions = [5, 10, 25, 50, 100];
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const rows = getTablesData(
		inputs,
		props.selectedStorico,
		props.copertura,
		props.selectedStandard,
		props.coperturaDaConfrontare
	);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = 0;

	return (
		<Box sx={{width: "100%"}}>
			<TableContainer>
				<Table
					sx={{width: "99%"}}
					aria-labelledby="tableTitle"
					size={"medium"}
				>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows?.length}
						headCells={props.headCells}
					/>
					<TableBody
						sx={{
							"& td": {
								color: "#033661",
								padding: "4px 8px 0 8px",
							},
						}}
					>
						{rows
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							?.map((row, index) => {
								return (
									<TableRow key={row.name}>
										<TableCell sx={{width: "5%"}}>
											{row.name.replace(/ .*/, '')}
										</TableCell>
										<TableCell sx={{width: "60%"}}>
											<b>{row.name.replace(/^[^ ]+ /, '')}</b>
										</TableCell>
										<TableCell sx={{width: "10%", textAlign: "center"}}>
											{row.percentageDifferent ? (
													<SvgPieProgress
														value={parseFloat(row.percentageDifferent)}
														size={64}
														label={cleanPercentage(row.percentageDifferent)}
														className={ weightFromPercentage(row.percentageDifferent) }
														border={1}
														borderOffset={0}
														hole={.75}
													/>
											) : (
												<div className={"weighted-unk"} style={{padding:"0 8px"}}>Nessun dato</div>
											)}
										</TableCell>
										<TableCell sx={{width:"5%",textAlign:"center"}}>
											{ row.percentageDifferent && row.percentage ? (
												parseFloat(row.percentageDifferent) > parseFloat(row.percentage) ? downIcon :
													(  parseFloat(row.percentageDifferent) < parseFloat(row.percentage) ? growIcon : stableIcon )
											) : <span className={"weighted-unk"}>—</span> }
										</TableCell>
										<TableCell sx={{width: "10%", textAlign: "center"}}>
											<SvgPieProgress
												value={parseFloat(row.percentage)}
												size={64}
												label={cleanPercentage(row.percentage)}
												className={ weightFromPercentage(row.percentage) }
												border={1}
												borderOffset={0}
												hole={.75}
											/>
										</TableCell>
									</TableRow>
								);
							})}
						{emptyRows > 0 && (
							<TableRow style={{height: 53 * emptyRows}}>
								<TableCell colSpan={6}/>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={rowsPerPageOptions}
				component="div"
				count={rows?.length}
				page={page}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				onPageChange={handleChangePage}
				labelRowsPerPage={"Righe per pagina"}
				labelDisplayedRows={({from, to, count}) => {
					return `${from}–${to} di ${count !== -1 ? count : `più di ${to}`}`;
				}}
				sx={{"& .MuiTablePagination-toolbar": {alignItems: "baseline"}}}
			/>
		</Box>
	);
}

export default ConfrontoTable;
