import React from "react";
export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
	const rads = (angleInDegrees-90) * Math.PI / 180.0;
	return {
		x: centerX + (radius * Math.cos(rads)),
		y: centerY + (radius * Math.sin(rads))
	};
}

export const arcDataFromPercent = (x, y, radius, pct = 0, startAngle = 0) => {
	if ( pct ) {
		const endAngle = Math.min(startAngle + 3.6 * pct, 360);
		const start = polarToCartesian(x, y, radius, endAngle);
		const end = polarToCartesian(x, y, radius, startAngle);
		if ( pct >= 100 || (start.x === end.x && start.y === end.y) ) {
			return {
				d : String([
					"M", x - radius, y,
					"A", radius, radius, 0, 1, 0, x, y - radius,
					"A", radius, radius, 0, 0, 0, x - radius, y,
					"Z"
				].join(" ")),
				angle: 360
			};
		}
		return {
			d : String([
				"M", x, y, start.x, start.y,
				"A", radius, radius, 0, ((endAngle - startAngle) <= 180 ? 0 : 1), 0, end.x, end.y,
				"Z"
			].join(" ")),
			angle: endAngle
		};
	}
	return { angle: startAngle };
};

export const arcPathFromPercent = (...args) => arcDataFromPercent.apply(undefined,args).d;

export const parseCircularProps = (props = {}, state= {}) => {
	state.size = props.size ?? 800;
	state.border = props.border ?? 1;
	state.color = props.color ?? "#369";
	state.bgColor = props.bgColor ?? "transparent";
	state.startAngle = props.startAngle || 0;
	state.radius = state.size / 2;
	state.borderColor = props.borderColor ?? ( state.border ? state.color : "transparent" );
	if (typeof props.borderOffset === "number") {
		state.borderOffset = props.borderOffset;
	} else {
		state.borderOffset = state.border;
		if (state.borderOffset > 2) state.borderOffset = 2;
	}
	if ( state.size > 400 ) state.sizeClass = "XL";
	else if ( state.size > 160 ) state.sizeClass = "L";
	else if ( state.size > 64 ) state.sizeClass = "M";
	else if ( state.size > 32 ) state.sizeClass = "S";
	else state.sizeClass = "XS";
	if ( typeof props.hole === "number" ) state.hole = props.hole;
	if ( state.hole ) {
		state.maskid = generateUid();
		state.mask = (
			<mask id={state.maskid}>
				<circle
					r={state.radius}
					cx={state.radius}
					cy={state.radius}
					fill={"white"}
				/>
				<circle
					r={(state.radius - state.border / 2) * state.hole}
					cx={state.radius}
					cy={state.radius}
					fill={"black"}
				/>
			</mask>
		);
	}
	return state;
};

export const cleanPercentage = ( p, decs = 2 ) => {
	switch (typeof p) {
		case "string" :
			if ( p === "" ) return "—";
			p = parseFloat(p);
		case "number" :
			p = p.toFixed(decs);
			break;
		default:
			return "—";
	}
	p = p.match(/[.,]/) ? p.replace(/0*$/,'').replace(/[.,]$/,'') : p;
	return p === "0" ? "—" : p+"%";
};

export const generateUid = ()=>{
	return ("10000000-1048-4000-8000-1400800110084").replace(/[018]/g, c =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
};

/* © 2023 balestra@altersoftware.it */
