import * as type from "./types";

const initialState = {
  error: false,
  refresh: true,
  success: false,
  warning: false,
  info: false,
  message: "",
  upload: false,
  dataPlatform: null,
  errorDetail: "",
  loading: false,
  selectStorico: [],
};

export function AppReducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        message: action.message ? action.message : state.message,
      });
    case type.SET_SUCCESS:
      return Object.assign({}, state, {
        success: action.success,
        message: action.message ? action.message : state.message,
      });
    case type.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.loading,
        message: action.message ? action.message : state.message,
      });
    case type.SET_WARNING:
      return Object.assign({}, state, {
        warning: action.warning,
        message: action.message ? action.message : state.message,
      });
    case type.SET_INFO:
      return Object.assign({}, state, {
        info: action.info,
        message: action.message ? action.message : state.message,
      });
    case type.SET_ERROR_DETAIL:
      return Object.assign({}, state, {
        errorDetail: action.errorDetail,
      });
    case type.SET_SELECT_STORICO:
      return Object.assign({}, state, {
        selectStorico: action.selectStorico,
      });
    case type.UPLOAD_FILE:
      return Object.assign({}, state, {
        refresh: true,
        error: action.error,
        message: action.message ? action.message : state.message,
        upload: action.upload,
        success: action.success,
        dataPlatform: action.dataPlatform,
      });
    case type.GET_PLATFORM:
      return Object.assign({}, state, {
        dataPlatform: action.dataPlatform,
      });
    default:
      return state;
  }
}
