import axios from "axios";

//const localApiUrl = "https://dp.phb.ovh/platform-service-rest/api";

const localApiUrl = (window.location.port == "3000" ? "https://dp-dev.phb.ovh" : (window.location.protocol + "//" + window.location.hostname)) + "/platform-service-rest/api";

const instance = axios.create({
	baseURL: localApiUrl,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
});

export default instance;
