import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { AuthReducer } from '../Reducer/AuthReducer'
import { AppReducer } from '../Reducer/AppReducer'

export const rootReducer = combineReducers({
    authReducer: AuthReducer,
    appReducer: AppReducer
})


const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
)

export default store;