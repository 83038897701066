import React from "react";
import SvgPie from "../../Custom/MySVG/SvgPie";

const PieMaturitaSvgSmall = class extends React.Component {
	constructor(prop) {
		super(prop);
		const data = prop?.data || {};
		this.state = {
			values : [
				{label: "Ottimizzate", value: data.ottimizzato,className:"weighted-max"},
				{label:"Gestite", value: data.gestito, className: "weighted-high"},
				{label:"Definite", value: data.definito, className: "weighted-medium"},
				{label: "Ripetibili", value: data.ripetibile,className: "weighted-low"},
				{label:"Iniziali", value: data.iniziale, className: "weighted-danger"}
			]
		};
	}

	render() {
		return(
			<SvgPie
				debug={this.state.debug}
				border={0}
				hole={this.props.hole||.5}
				size={this.props.size}
				values={this.state.values}
				labels={true}
			/>
		);
	}
};

export default PieMaturitaSvgSmall;