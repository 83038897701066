import * as type from "./types";
import instance from "../../Service/Api";

export const setError = (flag) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_ERROR,
      error: flag,
    });
  };
};

export const setSuccess = (flag) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_SUCCESS,
      success: flag,
    });
  };
};

export const setWarning = (flag) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_WARNING,
      warning: flag,
    });
  };
};

export const setInfo = (flag) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_INFO,
      info: flag,
    });
  };
};

export const setErrorDetail = (flag) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_ERROR_DETAIL,
      errorDetail: flag,
    });
  };
};

export const setLoading = (flag) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_LOADING,
      success: flag,
    });
  };
};

export const setSelectStorico = (flag) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_SELECT_STORICO,
      selectStorico: flag,
    });
  };
};

export const uploadFile = (data, username) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_ERROR_DETAIL,
      message: "",
    });
    instance
      .post("/carica-excel", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        dispatch({
          type: type.SET_INFO,
          info: true,
          message: "File running",
        });
        instance
          .get("/platform/getByFilter", {
            params: {
              username: username,
              applicativo: "dp",
            },
          })
          .then((res) => {
            return dispatch({
              type: type.UPLOAD_FILE,
              dataPlatform: res.data,
              upload: true,
              message: "File caricato con Successo!",
              success: true,
            });
          })
          .catch((error) => {
            return dispatch({
              type: type.SET_ERROR,
              error: true,
              message: error,
            });
          });
      })
      .catch(() => {
        return dispatch({
          type: type.SET_ERROR,
          error: true,
          upload: false,
          message: "Errore caricamento file!",
        });
      });
  };
};

export const getByFilter = (username) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_LOADING,
      loading: true,
    });
    instance
      .get("/platform/getByFilter", {
        params: {
          username: username,
          applicativo: "dp",
        },
      })
      .then((res) => {
        if (res.data) {
          return dispatch({
            type: type.GET_PLATFORM,
            dataPlatform: res.data,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: type.SET_LOADING,
          loading: false,
        });
      });
  };
};

export const registraUtente = (data) => {
  return (dispatch) => {
    instance
      .post("/security/registrazione", data)
      .then((res) => {
        return dispatch({
          type: type.SET_SUCCESS,
          message: res.data.message,
          success: true,
        });
      })
      .catch((error) => {
        return dispatch({
          type: type.SET_ERROR,
          message: error.response?.data?.message?.split(":")[1],
          error: true,
        });
      });
  };
};
