import { Box, Container, Paper, Grid, Typography, Button } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { setLogin } from "../Reducer/AuthReducer/authAction";
import CustomTextField from "../Ui/CustomTextField";

function Login(props){

    const [state, setState] = React.useState({
        username: '',
        password: '',
    })

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        props.setLogin(state);
    }

    return (
        <Container maxWidth="md" sx={{ padding: '4rem' }}>
            <Paper elevation={6}>
                <Grid padding={'2rem'} className='titoloSezioneCenter'>
                    <Typography
                        sx={{ display: 'flex', fontWeight: '600', alignItems: 'center', marginBottom: '8px !important', fontSize: '20px', color: '#033661' }}
                        component={'p'}
                    >
                        LOGIN
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'} textAlign={'center'} sx={{ paddingBottom: '1rem' }}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: { xs: '45ch', md: '50ch' }, marginRight: '5rem', marginLeft: '5rem',
                                '& .MuiFilledInput-root': { overflow: 'visible', height: '3.5rem' }
                            },
                            '& .MuiButton-root': { m: 1, color: '#033661', bgcolor:'#f0f0f0',
                                '&:hover': { backgroundColor: '#033661', color: '#fff' },
                                '&.Mui-disabled': { bgcolor:'transparent', color: '#00000042 !important' }
                            }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <CustomTextField
                                required
                                label="Username"
                                value={state.username}
                                variant="filled"
                                onChange={e => setState({ ...state, username: e.target.value })}
                                onKeyPress={e => { e.code === 'Enter' && handleSubmit() }}
                            />
                        </div>
                        <div>
                            <CustomTextField
                                required
                                label="Password"
                                autoComplete="off"
                                value={state.password}
                                variant="filled"
                                type={'password'}
                                onChange={e => setState({ ...state, password: e.target.value })}
                                onKeyPress={e => { e.code === 'Enter' && handleSubmit() }}
                            />
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                onClick={(event) => { handleSubmit(event) }}
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                disabled={state.username==='' || state.password===''}
                            >
                                Accedi
                            </Button>
                        </div>
                    </Box>
                </Grid>
            </Paper>
        </Container>
    )
}
const mapStateToProps = state => {
    return {
        utente: state.authReducer.utente
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogin: data => dispatch(setLogin(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)